import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ApprovalConfirm,
  Confirm,
  DropdownInput,
  TextInput,
  WarningIcon,
} from '../../../components';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { selectLoading, sendToast, updateAoboList } from '../../../store/slices/main/mainSlice';
import { seletIdpOptions } from '../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView, ClientForms, CustomerType, Status } from '../../../types';
import {
  CUSTOMER_STATUS_OPTIONS,
  CUSTOMER_SUB_TYPE_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
} from '../clientConstants';
import ClientService from '../clientService';

interface CustomerOverviewProps {
  activeStep?: string;
  mode: AddOrEditOrView;
  isApproveScreen?: boolean;
  canBeApproved?: boolean;
  visited?: Set<string>;
  currentEditiCustomer?: Client;
  parentCustomer?: Client;
  isStonexCustomer: boolean | undefined;
  onSaveAndProceed?: (client?: Client) => void;
  jumpToEdit?: (step: string) => void;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
  setCustomerType: (customerType: CustomerType) => void;
}

export const CustomerOverview = ({
  activeStep,
  mode,
  isApproveScreen = false,
  canBeApproved = false,
  visited,
  currentEditiCustomer,
  parentCustomer,
  isStonexCustomer,
  onSaveAndProceed,
  jumpToEdit,
  handleStepperValidation,
  setCustomerType,
}: CustomerOverviewProps) => {
  const {
    unregister,
    setValue,
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<CustomerOverviewFormData>();
  const customerType = watch('customerType');
  const idpApplications = useSelector(seletIdpOptions);
  const dispatch = useAppDispatch();
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [editClient, setEditClient] = useState<Client | null>(null);
  const [approved, setApproved] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showIdpChangeWarning, setShowIdpChangeWarning] = useState(false);
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    if (currentEditiCustomer) {
      setEditClient(currentEditiCustomer);
      setFormValues(currentEditiCustomer);
    } else {
      if (parentCustomer) {
        setCustomerTypeBasedOnParent(parentCustomer);
      }
    }
  }, [currentEditiCustomer, parentCustomer]);

  useEffect(() => {
    if (isStonexCustomer == false) {
      unregister('idpId');
    }
  }, [isStonexCustomer]);

  useEffect(() => {
    if (![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)) {
      unregister('status');
    }
  }, [mode]);

  useEffect(() => {
    if (!customerType) {
      return;
    }
    if (customerType != CustomerType.ORDERING_CUSTOMER) {
      unregister('enableForOnline');
      unregister('customerSubType');
    } else {
      register('enableForOnline');
      register('customerSubType');
    }
  }, [customerType]);

  const setCustomerTypeBasedOnParent = (parent: Client) => {
    if (parent.customerType == CustomerType.INSTRUCTING_INSTITUTION) {
      setValue('customerType', CustomerType.ORDERING_INSTITUTION);
    } else if (parent.customerType == CustomerType.ORDERING_INSTITUTION) {
      setValue('customerType', CustomerType.ORDERING_CUSTOMER);
    }
  };

  const setFormValues = (client: Client) => {
    setValue('customerShortName', client.customerShortName);
    setValue('customerType', client.customerType);
    setValue('idpId', client.idpId);
    setValue('status', client.status ?? client.approvalStatus);
    if (client.enableForOnline) {
      setValue('enableForOnline', client.enableForOnline);
    }
    if (client.customerSubType) {
      setValue('customerSubType', client.customerSubType);
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const saveCustomerDetails = async (formData: CustomerOverviewFormData) => {
    if (!isValid) {
      return;
    }
    if (mode == AddOrEditOrView.Add) {
      const submitData = {
        ...formData,
        owningCustomerId: parentCustomer?.id,
        enableForOnline: !!formData.enableForOnline,
      };
      const { customerShortName, ...rest } = submitData;
      const finalSubmitData = customerShortName ? submitData : rest;
      const { data, status } = await ClientService.createAccountOverview(finalSubmitData);
      if (status !== 200) {
        showToast('error', 'error', 'client.creation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.overview.saved.toast.desc');
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...finalSubmitData,
          });
          return;
        }
      }
      setCustomerType(formData.customerType);
      if (data?.data) {
        const _editClient = { ...data.data };
        setEditClient(_editClient);
        if (onSaveAndProceed) {
          onSaveAndProceed(_editClient);
        }
      }
    } else {
      if (!editClient?.id) {
        return;
      }
      const clientId: number = editClient?.id;
      const updateClient = { ...editClient, ...formData, id: clientId };
      const { status, data } = await ClientService.updateCustomer(updateClient, editClient?.id);
      if (status !== 200) {
        showToast('error', 'error', 'client.updation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.overview.saved.toast.desc');
          if (data?.data) {
            const _editClient = { ...data.data };
            if (onSaveAndProceed) {
              onSaveAndProceed(_editClient);
            }
          }
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...formData,
          });
          return;
        }
      }
    }

    if (handleStepperValidation) {
      handleStepperValidation(ClientForms.CustomerOverview, true);
    }
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  const disableCustomerDetails = () => {
    return (
      editClient != null &&
      editClient.approvalStatus != undefined &&
      jumpToEdit &&
      [Status.PENDING_APPROVAL, Status.PENDING_CREATION].includes(editClient?.approvalStatus)
    );
  };

  const enableEditAfterApproval = (client: Client) => {
    if (client.status === Status.ACTIVE) {
      setShowEditButton(true);
    }
  };

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v, _approved, approvedClient) => {
          setShowApproveConfirm(v);
          setApproved(_approved);
          if (currentEditiCustomer) {
            dispatch(updateAoboList(currentEditiCustomer));
          }
          if (_approved) {
            if (apporveOrject == 'approve') {
              if (approvedClient) {
                setFormValues(approvedClient);
                enableEditAfterApproval(approvedClient);
              }
            }
          }
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={ClientService.approveOrRject}
        id={currentEditiCustomer?.id}
        type='customer'
      />
      {/* Show warning for idp change */}
      <Confirm
        headerIcon={<WarningIcon />}
        title='change.idp.warning.title'
        message='change.idp.warning.message'
        onConfirm={() => {
          setShowIdpChangeWarning(false);
        }}
        singleButton='Confirm'
        visible={showIdpChangeWarning}
      />

      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.overview.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.overview.description' />
            </div>
            {disableCustomerDetails() && (
              <div className='text-error-1 mt-1'>
                <Translate value='client.edit.pending.disabled' />
              </div>
            )}
          </div>
          <div className=''>
            {isApproveScreen &&
              !approved &&
              currentEditiCustomer &&
              (currentEditiCustomer.approvalStatus === Status.PENDING_APPROVAL ||
                currentEditiCustomer.approvalStatus === Status.PENDING_CREATION) &&
              canBeApproved && (
                <>
                  <Button
                    disabled={isLoading}
                    severity='secondary'
                    onClick={() => {
                      setApporveOrject('reject');
                      setShowApproveConfirm(true);
                    }}
                  >
                    <Translate value='reject' />
                  </Button>

                  <Button
                    disabled={isLoading}
                    severity='info'
                    className='ml-[10px]'
                    onClick={() => {
                      setApporveOrject('approve');
                      setShowApproveConfirm(true);
                    }}
                  >
                    <Translate value='approve' />
                  </Button>
                </>
              )}
            {showEditButton && (
              <Button
                disabled={isLoading}
                severity='info'
                onClick={() => {
                  navigate(`/client/edit/${currentEditiCustomer?.id}`);
                }}
              >
                <Translate value='edit' />
              </Button>
            )}
          </div>
        </div>
        {activeStep === ClientForms.CustomerOverview || mode == AddOrEditOrView.View ? (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='lg:mx-5 mt-12 mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <div className='col-span-1'>
                <DropdownInput
                  label='customer_type'
                  placeholder='customer_type'
                  name='customerType'
                  isRequired
                  disabled={
                    mode === AddOrEditOrView.Edit ||
                    mode === AddOrEditOrView.View ||
                    !isStonexCustomer
                  }
                  control={control}
                  options={CUSTOMER_TYPE_OPTIONS}
                  rules={{ required: true }}
                  error={errors['customerType']}
                />
              </div>

              {customerType === CustomerType.ORDERING_CUSTOMER && (
                <div className='col-span-1'>
                  <DropdownInput
                    label='customerSubType'
                    placeholder='customerSubType'
                    name='customerSubType'
                    isRequired
                    disabled={mode === AddOrEditOrView.View}
                    control={control}
                    options={CUSTOMER_SUB_TYPE_OPTIONS}
                    rules={{ required: true }}
                    error={errors['customerSubType']}
                  />
                </div>
              )}

              <div className='col-span-1'>
                <TextInput
                  isRequired
                  label='customer_short_name'
                  disabled={mode === AddOrEditOrView.View}
                  placeholder='customer_short_name'
                  formRegister={register('customerShortName', { required: true })}
                  error={errors['customerShortName']}
                />
              </div>

              {[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
                <div className='col-span-1'>
                  <DropdownInput
                    className='block'
                    label='status'
                    placeholder='status'
                    disabled={true}
                    name='status'
                    control={control}
                    options={CUSTOMER_STATUS_OPTIONS}
                    error={errors['status']}
                  />
                </div>
              )}

              {isStonexCustomer && (
                <div className='col-span-1 idp'>
                  <DropdownInput
                    label='idpApplications'
                    placeholder='idpApplications'
                    disabled={mode === AddOrEditOrView.View}
                    name='idpId'
                    isRequired
                    control={control}
                    options={idpApplications}
                    rules={{ required: true }}
                    onChange={() => {
                      if (mode === AddOrEditOrView.Edit) {
                        setShowIdpChangeWarning(true);
                      }
                    }}
                    error={errors['idpId']}
                  />
                </div>
              )}
              {[CustomerType.ORDERING_CUSTOMER].includes(customerType) && (
                <div className='flex flex-col gap-3'>
                  <label htmlFor='enableForOnline'>
                    <Translate value='enableForOnline' /> <span className='text-error-1'>*</span>
                  </label>
                  <Controller
                    name='enableForOnline'
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        disabled={mode == AddOrEditOrView.View}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            {mode !== AddOrEditOrView.View && (
              <div className='flex items-center justify-center mt-10 gap-5'>
                <Button
                  type='submit'
                  severity='info'
                  onClick={() => {
                    if (handleStepperValidation) {
                      handleStepperValidation(ClientForms.CustomerOverview, isValid);
                    }
                    if (!isValid) {
                      showErrorToast();
                      return;
                    }
                  }}
                >
                  <Translate value='customer.overiew.save.btn.label' />
                </Button>
              </div>
            )}
          </form>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              onClick={() => {
                if (jumpToEdit) {
                  jumpToEdit(ClientForms.CustomerOverview);
                }
              }}
              disabled={disableCustomerDetails()}
            >
              <Translate value={visited?.has(ClientForms.CustomerOverview) ? 'edit' : 'start'} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
