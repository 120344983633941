import apiClient from '../../utils/apiClient';

const DashBoardService = {
  async getPendingDetails(): Promise<any> {
    return apiClient.post(
      '/api/pending-items-management/pending-item-stats',
      {},
      {
        headers: { skipLoader: true },
      },
    );
  },
};

export default DashBoardService;
