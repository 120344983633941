export const dashboardRole = {
  payment: 'PAYMENT_CREATE,PAYMENT_UPDATE,PAYMENT_VIEW,PAYMENT_APPROVE',
  PaymentCreate: 'PAYMENT_CREATE',
  PaymentUpdate: 'PAYMENT_UPDATE',
  PaymentView: 'PAYMENT_VIEW',
  PaymentApprove: 'PAYMENT_APPROVE',
  UserApprove: 'MANAGE_USER_APPROVE',
  ApprovalApprove: 'MANAGE_APPROVAL_WORKFLOW_APPROVE',
  ClientApprove: 'MANAGE_CUSTOMER_APPROVE',
  PendingWidgetRole:
    'PAYMENT_APPROVE,MANAGE_USER_APPROVE,MANAGE_APPROVAL_WORKFLOW_APPROVE,MANAGE_CUSTOMER_APPROVE',
};
