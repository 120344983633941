import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, type DialogProps } from 'primereact/dialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SuccessIcon, WarningIcon } from '../../../../components';
import { Translate, TranslateWithValues } from '../../../../i18n/translate';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import ApprovalsSerive from '../approvalsService';

type Props = {
  action: 'delete' | 'deactivate';
  approvalStructure: ApprovalStructure;
  feature: FeatureType;
};

export default function ActionConfirmPopup({
  action,
  approvalStructure,
  feature,
  ...props
}: DialogProps & Props) {
  const [isSuccess, setIsSuccess] = useState(false);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async () => {
      let response;
      if (action === 'delete') {
        response = await ApprovalsSerive.delete([approvalStructure.id]);
      } else {
        response = await ApprovalsSerive.deactivateApprovalStructure([approvalStructure.id]);
      }
      return response;
    },
    onSuccess: (response) => {
      const { data } = response;
      const { result } = data;
      if (result.success) {
        setIsSuccess(true);
        queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      } else {
        const { errors } = result;
        dispatch(
          sendToast({
            summary: 'error',
            severity: 'error',
            detail: errors[0].errorTextCode,
          }),
        );
      }
    },
    onError: () => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: `approvals.${action}_error`,
        }),
      );
    },
  });

  if (isSuccess) {
    return (
      <Dialog
        {...props}
        draggable={false}
        visible
        className='rounded-xl confirm-dialog w-[400px]'
        header={
          <div className='grid gap-4 px-5 py-5'>
            <div className='w-fit'>
              <SuccessIcon />
            </div>
            <div className='grid gap-2'>
              <h2 className='text-midnightBlue text-sm-bold'>
                <TranslateWithValues
                  value={
                    action === 'delete'
                      ? 'approvals.delete_success_header'
                      : 'approvals.deactivate_success_header'
                  }
                  params={{ name: approvalStructure.modelName }}
                />
              </h2>
              <p className='text-xs-medium text-unselected'>
                <Translate
                  value={
                    action === 'delete'
                      ? 'approvals.delete_success_description'
                      : 'approvals.deactivate_success_description'
                  }
                />
              </p>
            </div>
          </div>
        }
      >
        <Button
          severity='info'
          className='grid place-items-center w-full'
          onClick={() => props.onHide()}
        >
          <Translate value='done' />
        </Button>
      </Dialog>
    );
  }

  return (
    <Dialog
      {...props}
      draggable={false}
      visible
      className='rounded-xl confirm-dialog w-full max-w-[400px]'
      header={
        <div className='grid gap-4 px-5 py-5'>
          <div className='w-fit'>
            <WarningIcon />
          </div>
          <div className='grid gap-2'>
            <h2 className='text-midnightBlue text-sm-bold'>
              <TranslateWithValues
                value={`approvals.${action}_confirm_again_header`}
                params={{ name: approvalStructure.modelName }}
              />
            </h2>
            {action != 'delete' && (
              <p className='text-xs-medium text-unselected'>
                <Translate value='approvals.deactivate_confirm_again_description' />
              </p>
            )}
          </div>
        </div>
      }
    >
      <div className='grid gap-4'>
        <Button
          severity='info'
          className='flex gap-2 justify-center'
          onClick={() => mutation.mutate()}
          loading={mutation.isPending}
        >
          <Translate value='confirm' />
        </Button>
        <Button
          severity='secondary'
          className='grid place-items-center'
          onClick={() => props.onHide()}
        >
          <Translate value='cancel' />
        </Button>
      </div>
    </Dialog>
  );
}
