import { useSelector } from 'react-redux';
import { CustomTabView } from '../../../../components';
import { selectLoading } from '../../../../store/slices/main/mainSlice';
import Table from './Table';
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const isLoading = useSelector(selectLoading);
  const [searchParams] = useSearchParams();
  const tabs = [
    {
      header: 'All Users',
      content: <Table pending={false} />,
      loading: isLoading,
    },
    {
      header: 'Pending',
      content: <Table pending={true} />,
      loading: isLoading,
    },
  ];

  return (
    <>
      <div>
        <CustomTabView tabs={tabs} initialIndex={searchParams.get('pending') ? 1 : 0} />
      </div>
    </>
  );
};

export default Dashboard;
