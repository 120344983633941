import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Translate } from '../../../../../i18n/translate';
import { useAppDispatch } from '../../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../../store/slices/main/mainSlice';
import ApprovalsSerive from '../../approvalsService';
import '../index.scss';
import AddStructureSteps from './AddStructureSteps';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export default function TemplateDemo() {
  const [searchParams] = useSearchParams();
  const feature = searchParams.get('feature') as FeatureType | null;
  const modelId = searchParams.get('modelId');

  const location = useLocation();

  const isCreateApproval = location.pathname === '/manage/approvals/add-structure';

  if (!feature) {
    return (
      <h2 className='text-center'>
        <Translate value='invalid_feature_type' className='text-lg-bold' />
      </h2>
    );
  }

  if (isCreateApproval) {
    return <EditOrCreateApprovalStructureForm feature={feature} isCreateApproval />;
  }

  if (!modelId) {
    return (
      <h2 className='text-center'>
        <Translate value='modelId_not_found' className='text-lg-bold' />
      </h2>
    );
  }

  return <EditApprovalStructure modelId={Number(modelId)} />;
}

function EditApprovalStructure({ modelId }: { modelId: number }) {
  const initialData = useQuery({
    queryKey: ['approval_structure_by_modelId', modelId],
    queryFn: async () => {
      const data = await ApprovalsSerive.getApprovalStructureByModelId(modelId);
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (initialData.isLoading) {
    return <div className='text-center'>Loading...</div>;
  }

  if (!initialData.data) {
    return (
      <div className='text-center'>
        <Translate value='approvals.structure_not_found' />
      </div>
    );
  }

  return (
    <EditOrCreateApprovalStructureForm
      modelId={modelId}
      feature={initialData.data.feature}
      isCreateApproval={false}
      initialData={initialData.data}
    />
  );
}

type EditOrCreateApprovalStructureForm = {
  feature: FeatureType;
} & (
  | {
      isCreateApproval: true;
    }
  | {
      isCreateApproval: false;
      initialData: ApprovalStructure;
      modelId: number;
    }
);

function EditOrCreateApprovalStructureForm(props: EditOrCreateApprovalStructureForm) {
  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);

  const navigate = useNavigate();

  const { feature, isCreateApproval } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [formInput, setFormInput] = useState<{
    modelName: string;
    approverCategory: 'USER' | 'ROLE';
    extendChildApproval: boolean;
    appStructure: AppStructureType;
    appModelConfigs: Array<AppModelConfig>;
    appNotifications: Array<{ userId: number }>;
  }>(
    isCreateApproval
      ? {
          modelName: '',
          appStructure: 'SIMPLE',
          approverCategory: 'USER',
          appModelConfigs: [],
          appNotifications: [],
          extendChildApproval: true,
        }
      : {
          modelName: props.initialData.modelName,
          appStructure: props.initialData.appStructure,
          approverCategory: props.initialData.approverCategory,
          appModelConfigs: props.initialData.appModelConfigs,
          appNotifications: props.initialData.appNotifications,
          extendChildApproval: props.initialData.extendChildApproval,
        },
  );
  const getToastMessage = (type: 'success' | 'failed') => {
    switch (feature) {
      case 'PAYMENT':
        return isCreateApproval
          ? `create_payment_approval.${type === 'success' ? 'success' : 'failed'}`
          : `update_payment_approval.${type === 'success' ? 'success' : 'failed'}`;
      case 'BENEFICIARY_MANAGEMENT':
        return isCreateApproval
          ? `create_beneficiary_approval.${type === 'success' ? 'success' : 'failed'}`
          : `update_beneficiary_approval.${type === 'success' ? 'success' : 'failed'}`;
      case 'MANAGE_APPROVAL_WORKFLOW':
        return isCreateApproval
          ? `create_compliance_approval.${type === 'success' ? 'success' : 'failed'}`
          : `update_compliance_approval.${type === 'success' ? 'success' : 'failed'}`;
      case 'MANAGE_CUSTOMER':
        return isCreateApproval
          ? `create_customer_approval.${type === 'success' ? 'success' : 'failed'}`
          : `update_customer_approval.${type === 'success' ? 'success' : 'failed'}`;
      case 'MANAGE_USER':
        return isCreateApproval
          ? `create_user_approval.${type === 'success' ? 'success' : 'failed'}`
          : `update_user_approval.${type === 'success' ? 'success' : 'failed'}`;
    }
  };

  const mutation = useMutation({
    mutationFn: async () => {
      let response;
      if (isCreateApproval) {
        const requestBody = {
          ...formInput,
          owningCustomerId: currentCustomer!.id!,
          feature,
          isOwnApproval: true,
          status: 'ACTIVE' as ApprovalStructure['status'],
        };
        response = await ApprovalsSerive.createPaymentApprovalStructure(
          formInput.appStructure === 'SEQUENTIAL'
            ? {
                ...requestBody,
                appModelConfigs: formInput.appModelConfigs.map((config, index) => ({
                  ...config,
                  levelSequence: index + 1,
                })),
              }
            : requestBody,
        );
      } else {
        const requestBody = {
          ...props.initialData,
          ...formInput,
        };
        requestBody.appModelConfigs.forEach((config) => {
          config.appApprovers.forEach((approver) => {
            approver.appConfigId = config.id;
          });
        });
        response = await ApprovalsSerive.updatePaymentApprovalStructure(
          formInput.appStructure === 'SEQUENTIAL'
            ? {
                ...requestBody,
                appModelConfigs: formInput.appModelConfigs.map((config, index) => ({
                  ...config,
                  levelSequence: index + 1,
                })),
              }
            : requestBody,
        );
      }
      return response;
    },
    onSuccess: () => {
      navigate(`/manage/approvals?tab=${feature}`);
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: getToastMessage('success'),
        }),
      );
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: getToastMessage('failed'),
          detail: error.message,
        }),
      );
    },
  });

  return (
    <div className='grid gap-6'>
      <AddStructureSteps activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      {activeIndex === 0 && (
        <Step1
          feature={feature}
          isCreateApproval={isCreateApproval}
          modelId={isCreateApproval ? undefined : props.modelId}
          initialData={{
            modelName: formInput.modelName,
            appStructure: formInput.appStructure,
            approverCategory: formInput.approverCategory,
            extendChildApproval: formInput.extendChildApproval,
          }}
          submitStep={({ modelName, appStructure, approverCategory, extendChildApproval }) => {
            const appModelConfig: AppModelConfig = {
              appApprovers: [],
              reqAppCount: 1,
            };

            if (appStructure === 'TIERED') {
              appModelConfig.currency = currentCustomer?.currency || 'USD';
              appModelConfig.minThreshold = '';
              appModelConfig.maxThreshold = '';
            }

            if (isCreateApproval) {
              setFormInput({
                modelName,
                appStructure,
                approverCategory,
                appModelConfigs: [appModelConfig],
                appNotifications: [],
                extendChildApproval,
              });
            } else {
              setFormInput((prev) => ({
                modelName,
                appStructure: prev.appStructure,
                approverCategory,
                appModelConfigs:
                  approverCategory === prev.approverCategory
                    ? prev.appModelConfigs
                    : [appModelConfig],
                appNotifications: prev.appNotifications,
                extendChildApproval,
              }));
            }
            setActiveIndex(1);
          }}
        />
      )}
      {activeIndex === 1 && (
        <Step2
          feature={feature}
          appStructure={formInput.appStructure}
          approverCategory={formInput.approverCategory}
          initialData={formInput.appModelConfigs}
          submitStep={(appModelConfigs) => {
            setFormInput((prev) => ({
              ...prev,
              appModelConfigs,
            }));
            setActiveIndex(2);
          }}
        />
      )}
      {activeIndex === 2 && (
        <Step3
          formData={{
            owningCustomerId: currentCustomer?.id,
            feature,
            ...formInput,
          }}
          updateDetails={(appModelConfigs) => {
            setFormInput((prev) => ({
              ...prev,
              appModelConfigs,
            }));
          }}
          submitStep={() => setActiveIndex(3)}
        />
      )}
      {activeIndex === 3 && (
        <Step4
          feature={feature}
          selectedNotifiers={formInput.appNotifications.map((n) => n.userId)}
          updateSelectedNotifiers={(newNotifiers) => {
            setFormInput((prev) => ({
              ...prev,
              appNotifications: newNotifiers.map((notifier) => ({
                userId: notifier,
              })),
            }));
          }}
          isPending={mutation.isPending}
          isCreateApproval={isCreateApproval}
          submit={() => mutation.mutate()}
        />
      )}
    </div>
  );
}
