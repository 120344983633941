import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Translate } from '../../../../../i18n/translate';
import { ToggleInput } from '../../../../../components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SelectNotifyUsers from './SelectNotifyUsers';
import ApprovalsSerive from '../../approvalsService';
import { selectCurrentCustomer } from '../../../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';

type Props = {
  feature: FeatureType;
  selectedNotifiers: Array<number>;
  updateSelectedNotifiers: (newNotifiers: Array<number>) => void;
  submit: () => void;
  isPending: boolean;
  isCreateApproval: boolean;
};

export default function Step4({
  feature,
  selectedNotifiers,
  updateSelectedNotifiers,
  submit,
  isPending,
  isCreateApproval,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();

  const selectedCustomer = useSelector(selectCurrentCustomer);

  useQuery({
    queryKey: ['getUsersByFeature', feature, selectedCustomer?.id],
    queryFn: async () => {
      const data = await ApprovalsSerive.getUsersByFeature(feature);
      return data;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div>
        <div className='card px-4 lg:px-14 py-10'>
          <div className='grid gap-1'>
            <Translate
              value='create_approval.receive_notification'
              className='text-neutral-1 text-lg-semibold'
            />
            <Translate
              value='create_approval.receive_notification.description'
              className='text-neutral-3 text-sm-regular'
            />
          </div>
          <div className='pt-6 flex gap-10'>
            <div className='grid gap-1'>
              <Translate
                value='create_approval.manage_notification'
                className='text-neutral-1 text-sm-medium'
              />
              <Translate
                value='create_approval.manage_notification.description'
                className='text-neutral-3 text-xs-regular'
              />
            </div>
            {isCreateApproval ? (
              <ToggleInput
                id='manageNotification'
                label=''
                gradient
                checked={dialogOpen || selectedNotifiers.length > 0}
                buttonAction={() => {
                  if (selectedNotifiers.length === 0) {
                    setDialogOpen(true);
                  } else {
                    updateSelectedNotifiers([]);
                  }
                }}
              />
            ) : (
              <Button severity='info' className='w-fit h-fit' onClick={() => setDialogOpen(true)}>
                Change notifiers
              </Button>
            )}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row gap-4 w-full justify-center mt-10'>
          <Button
            type='button'
            severity='contrast'
            className='justify-center'
            onClick={() => navigate(`/manage/approvals?tab=${feature}`)}
          >
            <Translate value='cancel' />
          </Button>
          <Button
            type='submit'
            severity='info'
            className='sm:w-60 justify-center gap-2'
            onClick={submit}
            disabled={selectedNotifiers.length === 0 || isPending}
            loading={isPending}
          >
            <Translate value='create_approval.save_structure' />
          </Button>
        </div>
      </div>
      <Dialog
        visible={dialogOpen}
        draggable={false}
        onHide={() => setDialogOpen(false)}
        className='w-full max-w-[400px] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        header={
          <div className='grid gap-1 px-3'>
            <div className='text-lg font-semibold text-neutral-1'>
              <Translate value='notification_user_list_modal_header' />
            </div>
            <div className='text-sm font-regular text-neutral-3'>
              <Translate
                value={'notification_user_list_modal_description'}
                className='!text-sm-regular'
              />
            </div>
          </div>
        }
        headerClassName='border-b border-neutral-surface-subtle'
      >
        <SelectNotifyUsers
          initialSelectedUsers={selectedNotifiers}
          feature={feature}
          updateList={(users) => {
            updateSelectedNotifiers(users);
            setDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}
