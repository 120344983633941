import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Status } from '../../../components';
import { translateWithValues } from '../../../i18n/translate';
import { ChevronRight, Close } from '../../../icons';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { NOTIFICATION_STATUS } from '../../../types';
import {
  convertDateFormat,
  convertToTimeFormat,
  formatDateRelativeTime,
} from '../../../utils/helper';
import NotificationService from '../notificationService';

const ContentTemplate = ({
  rowData,
  isSidebar = false,
  setFilterModalToggle,
}: {
  rowData: NotificationMessage;
  isSidebar?: boolean;
  setFilterModalToggle?: (visible: boolean) => void;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getNotificationTitle = (rowData: NotificationMessage) => {
    const notificationTitleMapping: { [key: string]: string } = {
      PAYMENT_APPROVAL: 'approval_needed_for_payment.notification',
    };
    const params = JSON.parse(rowData.notificationParam);
    const message = notificationTitleMapping[rowData.notificationType];
    return translateWithValues(message, params);
  };

  const handleNavigation = (rowData: NotificationMessage) => {
    console.log('rowData', rowData);
    const notificationParam = JSON.parse(rowData.notificationParam);
    const routeMapping: { [key: string]: string } = {
      PAYMENT_APPROVAL: `/payment/rate-requests/approve?orderId=${notificationParam.orderId}`,
    };
    navigate(routeMapping[rowData.notificationType]);
    if (setFilterModalToggle) {
      setFilterModalToggle(false);
    }
    readNotificationMutation.mutate(rowData.id);
  };

  const deleteNotificationMutation = useMutation({
    mutationFn: async (id: number) => {
      const payload = {
        notificationIds: [id],
      };
      const response = await NotificationService.deleteNotification(payload);
      return response;
    },
    onSuccess: (response) => {
      if (response?.data.success) {
        showToast('success', 'deleted_notification', '');
        queryClient.invalidateQueries({
          queryKey: ['notifications_query'],
        });
        queryClient.invalidateQueries({
          queryKey: ['notifications_query_sidebar'],
        });
      }
    },
  });

  const readNotificationMutation = useMutation({
    mutationFn: async (id: number) => {
      const payload = {
        notificationIds: [id],
      };
      const response = await NotificationService.readNotification(payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications_query'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications_query_sidebar'],
      });
    },
  });

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  return (
    <div className='w-full'>
      <div className='flex justify-between gap-1'>
        <div className='flex flex-col gap-1 w-5/6'>
          {!isSidebar && (
            <div className='flex gap-2'>
              {rowData.status === NOTIFICATION_STATUS.UNREAD && <Status status='NEW' />}
              {rowData.status === NOTIFICATION_STATUS.DELETED && <Status status='DELETED' />}
              <Status status={rowData.notificationType} />
            </div>
          )}
          <span className='text-sm-semibold text-neutral-2'>{getNotificationTitle(rowData)}</span>
        </div>
        {rowData.status !== NOTIFICATION_STATUS.DELETED && (
          <div className='flex justify-end gap-4'>
            <Button
              className='!p-0'
              onClick={() => {
                handleNavigation(rowData);
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              className='!p-0'
              onClick={() => {
                deleteNotificationMutation.mutate(rowData.id);
              }}
            >
              <Close />
            </Button>
          </div>
        )}
      </div>
      <div className={`text-neutral-3 text-sm-regular ${!isSidebar ? 'text-right' : ''}`}>
        {isSidebar
          ? formatDateRelativeTime(rowData.createdAt)
          : `${convertDateFormat(rowData.createdAt)} | ${convertToTimeFormat(rowData.createdAt)}`}
      </div>
    </div>
  );
};

export default ContentTemplate;
