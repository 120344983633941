import getCountryISO2 from '../CountrySelect/isoncCodeMapping';

const CountryFlag = ({ countryCode }: { countryCode: string }) => {
  try {
    return countryCode ? (
      <div className='w-max bg-neutral-surface-dark pb-[1px] pt-[4px] pr-[8px] pl-[3px] h-[30px]  rounded-[16px]'>
        <img
          className='rounded-full w-[19px] h-[19px] object-cover inline-block'
          src={`/images/country_flags/${getCountryISO2(countryCode).toLowerCase()}.svg`}
          alt={countryCode || `${countryCode} flag`}
        />
        <span className='text-xs-medium text-neutral-1 ml-1'>{countryCode}</span>
      </div>
    ) : (
      <></>
    );
  } catch (error) {
    console.error(`Flag not found for country code: ${countryCode}`);
    return null;
  }
};

export default CountryFlag;
