import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, TextInput } from '../../../components';
import CountrySelect from '../../../components/CountrySelect';
import getCountryISO2 from '../../../components/CountrySelect/isoncCodeMapping';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { selectLoading, sendToast } from '../../../store/slices/main/mainSlice';
import { fetchSellCurrency } from '../../../store/slices/refdata/actions';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../store/slices/refdata/refdataSlice';
import {
  AddOrEditOrView,
  ClientForms,
  CustomerType,
  SettlementMethod,
  Status,
} from '../../../types';
import {
  BANK_TYPE_OPTIONS,
  EMAIL_VALIDATION_REGEX,
  KYC_INDICATOR_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
} from '../clientConstants';
import ClientService from '../clientService';

interface CustomerDetailsProps {
  activeStep?: string;
  mode?: AddOrEditOrView;
  customerType: CustomerType | undefined;
  currentEditiCustomer?: Client;
  parentCustomer?: Client;
  isStonexCustomer: boolean | undefined;
  visited?: Set<string>;
  visitPermitted?: Set<string>;
  onSaveAndProceed?: (client?: Client) => void;
  jumpToEdit?: (step: string) => void;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
}

const CustomerDetails = ({
  activeStep,
  onSaveAndProceed,
  jumpToEdit,
  mode = AddOrEditOrView.Add,
  visited,
  visitPermitted,
  handleStepperValidation,
  customerType,
  currentEditiCustomer,
  parentCustomer,
  isStonexCustomer,
}: CustomerDetailsProps) => {
  const [editClient, setEditClient] = useState<Client | null>(null);
  const countriesList = useSelector(selectCountries);
  const currencies = useSelector(selectCustomerCurrencies);
  const [stnxLvlSellCurrencis, setStnxLvlSellCurrencis] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const {
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    unregister,
    formState: { errors, isValid },
  } = useForm<CustomerDetailsFormData>();

  const formValues = watch();
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    if (currentEditiCustomer) {
      setEditClient(currentEditiCustomer);
      setFormValues(currentEditiCustomer);
      dispatch(fetchSellCurrency(currentEditiCustomer.owningCustomerId));
    }
  }, [currentEditiCustomer]);

  useEffect(() => {
    if (isStonexCustomer === false) {
      unregister(['stoneXId']);
    }
    if (customerType === CustomerType.INSTRUCTING_INSTITUTION) {
      unregister(['customerIdentifier', 'bankType', 'bankABA', 'accountNumber']);
    }
    if (
      customerType === CustomerType.ORDERING_CUSTOMER ||
      customerType === CustomerType.INSTRUCTING_INSTITUTION
    ) {
      unregister(['doddFrank']);
    }

    if (customerType === CustomerType.ORDERING_CUSTOMER) {
      if (isStonexCustomer === false) {
        unregister(['settlementMethod', 'customerCurrency']);
      }
    }
  }, [customerType, isStonexCustomer]);

  useEffect(() => {
    if (mode == AddOrEditOrView.Edit && isStonexCustomer && currentEditiCustomer?.stoneXId) {
      getStonexLevelCurrencies(currentEditiCustomer.stoneXId);
    }
  }, [isStonexCustomer]);

  const getStonexLevelCurrencies = async (stoneXId: string) => {
    const { data, status } = await ClientService.getCustomerByStonexClientId(stoneXId);
    if (status !== 200) {
      showToast('error', 'error', 'stonex.client.fetch.error.toast.desc');
      return;
    } else if (!data.result.success || data.data.value.length === 0) {
      showToast('error', 'error', 'stonex.client.fetch.notfound.toast.desc');
    } else {
      const clients = data.data.value as StonexClient[];
      if (Array.isArray(clients) && clients.length > 0) {
        const client = clients[0];
        const sellCurrencies = client?.ProductsCurrencies?.Currencies?.ClientSellCcy ?? [];
        setStnxLvlSellCurrencis(sellCurrencies);
      }
    }
  };

  const setFormValues = (client: Client) => {
    setValue('street1', client.address?.street1 ?? '');
    setValue('street2', client.address?.street2 ?? '');
    setValue('city', client.address?.city ?? '');
    setValue('state', client.address?.state ?? '');
    setValue('postalCode', client.address?.postalCode ?? '');
    setValue('customerLegalName', client.customerLegalName);
    setValue(
      'country',
      countriesList.find(
        (country) =>
          country.code === client.address?.country ||
          getCountryISO2(country.code) === client.address?.country,
      ) ?? undefined,
    );
    setValue('phone', client.phone);
    setValue('email', client.email);
    setValue('doddFrank', client.isDoddFrank);
    setValue('customerIdentifier', client.customerIdentifier);
    if (client.settlementMethods && client.settlementMethods[0]) {
      setValue('settlementMethod', client.settlementMethods[0]);
    }
    setValue('stoneXId', client.stoneXId);
    const currency = currencies.find((currency) => currency.isocode === client.currency);
    if (currency) {
      setValue('customerCurrency', currency.isocode);
    }
    if (client.bankInfos && client.bankInfos[0]) {
      setValue('accountNumber', client.bankInfos[0].accountNumber);
      setValue('bankABA', client.bankInfos[0].routingCode);
      setValue('bankType', client.bankInfos[0].codeType);
    }
    setValue('kycStatus', client.kycStatus);
  };

  const getStonexCustomerData = async (stonexClientId: string) => {
    const { data, status } = await ClientService.getCustomerByStonexClientId(stonexClientId);
    if (status !== 200) {
      showToast('error', 'error', 'stonex.client.fetch.error.toast.desc');
      return;
    } else if (!data.result.success || data.data.value.length === 0) {
      showToast('error', 'error', 'stonex.client.fetch.notfound.toast.desc');
    } else {
      const clients = data.data.value as StonexClient[];
      if (Array.isArray(clients) && clients.length > 0) {
        const client = clients[0];
        const { Companyconfig } = client;
        const { Basicinfo } = client;
        const { Address } = Basicinfo;
        const country = countriesList.find(
          (country: Country) => getCountryISO2(country.code) === Address.CountryCode,
        );
        const sellCurrencies = client?.ProductsCurrencies?.Currencies?.ClientSellCcy ?? [];
        setStnxLvlSellCurrencis(sellCurrencies);
        setValue('customerLegalName', Basicinfo.Fullname);
        setValue('street1', Address.Street);
        setValue('street2', Address.AddressLines[1]);
        setValue('city', Address.City);
        setValue('state', Address.State);
        setValue('postalCode', Address.PostalCode);
        setValue('country', country);
        setValue('email', Basicinfo.Email);
        setValue('doddFrank', Companyconfig.DoddFrank);
        await trigger();
      }
    }
  };

  const saveCustomerDetails = async (customerData: CustomerDetailsFormData) => {
    if (!isValid) {
      return;
    }
    const submitData: any = {
      ...currentEditiCustomer,
      ...customerData,
      customerIdentifier: customerData.customerIdentifier,
      stree1: undefined,
      customerUrl: 'test',
      street2: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      customerCurrency: undefined,
      isDoddFrank: customerData.doddFrank,
      settlementMethods: customerData.settlementMethod ? [customerData.settlementMethod] : [],
      currency: customerData.customerCurrency ?? '',
      address: {
        street1: customerData.street1,
        street2: customerData.street2,
        city: customerData.city,
        state: customerData.state,
        postalCode: customerData.postalCode,
        country: customerData.country?.code ?? '',
      },
      bankInfos: [],
      tenantId: 1,
    };
    if (parentCustomer) {
      submitData.owningCustomerId = parentCustomer.id;
    }

    if (customerType !== CustomerType.INSTRUCTING_INSTITUTION) {
      submitData.bankInfos = [
        {
          accountNumber: customerData.accountNumber,
          routingCode: customerData.bankABA,
          codeType: customerData.bankType,
        },
      ];
    }
    if (mode == AddOrEditOrView.Add) {
      const { data, status } = await ClientService.createAccountDetails(submitData as Client);
      if (status !== 200) {
        showToast('error', 'error', 'client.creation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.details.saved.toast.desc');
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...submitData,
          });
          return;
        }
      }
      if (data?.data) {
        setEditClient(data.data as Client);
        if (onSaveAndProceed) {
          onSaveAndProceed(data.data);
        }
      }
    } else {
      if (!editClient?.id) {
        return;
      }
      const clientId: number = editClient?.id;
      const updateClient = { ...submitData, id: clientId };
      const { status, data } = await ClientService.updateCustomer(
        updateClient as Client,
        editClient?.id,
      );
      if (status !== 200) {
        showToast('error', 'error', 'client.updation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.details.saved.toast.desc');
          if (data?.data) {
            const _editClient = { ...data.data };
            if (onSaveAndProceed) {
              onSaveAndProceed(_editClient);
            }
          }
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...submitData,
          });
          return;
        }
      }
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const disableCustomerDetails = () => {
    const client = editClient;
    return (
      client != null &&
      client.approvalStatus != undefined &&
      jumpToEdit &&
      [Status.PENDING_APPROVAL, Status.PENDING_CREATION].includes(client?.approvalStatus)
    );
  };

  return (
    <>
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
            {disableCustomerDetails() && (
              <div className='text-error-1 mt-1'>
                <Translate value='client.edit.pending.disabled' />
              </div>
            )}
          </div>
        </div>
        {(activeStep === ClientForms.CustomerDetails || mode == AddOrEditOrView.View) &&
        customerType ? (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='lg:mx-5 mt-12 mb-6 grid grid-cols-1 md:grid-cols-2 gap-5'>
              {isStonexCustomer && (
                <div className='col-span-1'>
                  <div className='flex flex-row items-end'>
                    <TextInput
                      className='w-full'
                      label='stonexClientId'
                      disabled={
                        mode == AddOrEditOrView.View ||
                        (mode == AddOrEditOrView.Edit && !!editClient?.stoneXId)
                      }
                      placeholder='stonexClientId'
                      isRequired
                      formRegister={register('stoneXId', { required: true })}
                      error={errors['stoneXId']}
                    />

                    <Button
                      className='inline h-[43px] ml-0'
                      severity='info'
                      label='search'
                      disabled={
                        mode == AddOrEditOrView.View ||
                        isLoading ||
                        (mode == AddOrEditOrView.Edit && !!editClient?.stoneXId)
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        getStonexCustomerData(formValues.stoneXId);
                      }}
                    ></Button>
                  </div>
                </div>
              )}
            </div>
            <div className='lg:mx-5 mb-6 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-5'>
              <div className='col-span-1 md:col-span-2'>
                <TextInput
                  label='customer_name'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='customer_name'
                  isRequired
                  formRegister={register('customerLegalName', { required: true })}
                  error={errors['customerLegalName']}
                />
              </div>

              <div className='col-span-1'>
                <DropdownInput
                  label='kycStatus'
                  placeholder='kycStatus'
                  disabled={mode == AddOrEditOrView.View}
                  name='kycStatus'
                  isRequired
                  control={control}
                  options={KYC_INDICATOR_OPTIONS}
                  rules={{ required: true }}
                  error={errors['kycStatus']}
                />
              </div>

              <div className='col-span-1'>
                <TextInput
                  label='address_line_1'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='address_line_1'
                  isRequired
                  formRegister={register('street1', { required: true })}
                  error={errors['street1']}
                />
              </div>

              <div className='col-span-1'>
                <TextInput
                  label='address_line_2'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='street2'
                  formRegister={register('street2')}
                />
              </div>
              <div className='col-span-1'>
                <TextInput
                  label='city'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='city'
                  isRequired
                  formRegister={register('city', { required: true })}
                  error={errors['city']}
                />
              </div>

              <div className='col-span-1'>
                <TextInput
                  label='state'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='state'
                  formRegister={register('state')}
                  error={errors['state']}
                />
              </div>
              <div className='col-span-1'>
                <TextInput
                  label='zip_code'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='zip_code'
                  isRequired
                  formRegister={register('postalCode', { required: true })}
                  error={errors['postalCode']}
                />
              </div>

              <div className='col-span-1'>
                <CountrySelect
                  label='country'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='country'
                  name='country'
                  isRequired
                  control={control}
                  rules={{ required: true }}
                  error={errors['country']}
                />
              </div>
              <div className='col-span-1'>
                <TextInput
                  label='phone'
                  disabled={isStonexCustomer || mode == AddOrEditOrView.View}
                  placeholder='phone'
                  formRegister={register('phone')}
                  error={errors['phone']}
                />
              </div>

              <div className='col-span-1'>
                <TextInput
                  label='email_id'
                  disabled={mode == AddOrEditOrView.View}
                  placeholder='email_id'
                  formRegister={register('email', {
                    required: true,
                    pattern: {
                      value: EMAIL_VALIDATION_REGEX,
                      message: 'invalid_email_address',
                    },
                  })}
                  isRequired
                  error={errors['email']}
                />
              </div>

              {!(!isStonexCustomer && [CustomerType.ORDERING_CUSTOMER].includes(customerType)) && (
                <>
                  <div className='col-span-1'>
                    <DropdownInput
                      label='settlement_method'
                      placeholder='settlement_method'
                      disabled={mode == AddOrEditOrView.View}
                      name='settlementMethod'
                      isRequired
                      control={control}
                      options={SETTLEMENT_METHOD_OPTIONS.filter((method) => {
                        if (isStonexCustomer) {
                          return method.value != SettlementMethod.AUTO_DEBIT;
                        } else {
                          return method.value != SettlementMethod.SWEEP;
                        }
                      })}
                      rules={{ required: true }}
                      error={errors['settlementMethod']}
                    />
                  </div>
                  <div className='col-span-1'>
                    <CurrencySelect
                      interSectSellCurrencies={isStonexCustomer ? stnxLvlSellCurrencis : undefined}
                      includeAll={false}
                      currencySource='sell'
                      label='customer_currency'
                      placeholder='customer_currency'
                      name='customerCurrency'
                      isRequired
                      control={control}
                      rules={{ required: true }}
                      disabled={mode == AddOrEditOrView.View}
                      error={errors['customerCurrency']}
                    />
                  </div>
                </>
              )}

              {[CustomerType.ORDERING_CUSTOMER, CustomerType.ORDERING_INSTITUTION].includes(
                customerType,
              ) && (
                <>
                  <div className='col-span-1'>
                    <TextInput
                      label='customer_identifier'
                      placeholder='customer_identifier'
                      isRequired
                      disabled={mode == AddOrEditOrView.View}
                      formRegister={register('customerIdentifier', { required: true })}
                      error={errors['customerIdentifier']}
                    />
                  </div>
                  <div className='col-span-1'>
                    <TextInput
                      label='account_number'
                      placeholder='account_number'
                      disabled={mode == AddOrEditOrView.View}
                      formRegister={register('accountNumber')}
                      error={errors['accountNumber']}
                    />
                  </div>
                  <div className='col-span-1'>
                    <TextInput
                      label='bank_code'
                      placeholder='bank_code'
                      disabled={mode == AddOrEditOrView.View}
                      formRegister={register('bankABA')}
                      error={errors['bankABA']}
                    />
                  </div>
                  <div className='col-span-1'>
                    <DropdownInput
                      label='bank_type'
                      placeholder='bank_type'
                      disabled={mode == AddOrEditOrView.View}
                      name='bankType'
                      control={control}
                      options={BANK_TYPE_OPTIONS}
                      error={errors['bankType']}
                    />
                  </div>
                </>
              )}

              {[CustomerType.ORDERING_INSTITUTION].includes(customerType) && (
                <div className='col-span-1'>
                  <div className='flex flex-col gap-3'>
                    <label htmlFor='doddFrank'>
                      <Translate value='dodd_frank' /> <span className='text-error-1'>*</span>
                    </label>
                    <Controller
                      name='doddFrank'
                      control={control}
                      render={({ field }) => (
                        <InputSwitch
                          disabled={mode == AddOrEditOrView.View}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.value)}
                        />
                      )}
                    />{' '}
                  </div>
                </div>
              )}
            </div>
            {mode !== AddOrEditOrView.View && (
              <div className='flex items-center justify-center mt-10 gap-5'>
                <Button
                  disabled={isLoading}
                  type='submit'
                  severity='info'
                  onClick={() => {
                    if (handleStepperValidation)
                      handleStepperValidation(ClientForms.CustomerDetails, isValid);
                    if (!isValid) {
                      showToast(
                        'error',
                        'client.field.validation.error',
                        'client.field.validation.error.detail',
                      );
                      return;
                    }
                  }}
                >
                  <Translate value='customer.details.save.btn.label' />
                </Button>
              </div>
            )}
          </form>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={
                !visitPermitted?.has(ClientForms.CustomerDetails) || disableCustomerDetails()
              }
              onClick={() => {
                if (jumpToEdit) {
                  jumpToEdit(ClientForms.CustomerDetails);
                }
              }}
            >
              <Translate value={visited?.has(ClientForms.CustomerDetails) ? 'edit' : 'start'} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerDetails;