import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Translate } from '../../../i18n/translate';
import { ChevronRight } from '../../../icons';
import { sendToast } from '../../../store/slices/main/mainSlice';
import ContentTemplate from '../Dashboard/ContentTemplate';
import NotificationService from '../notificationService';
import './notificationSidebar.scss';

interface NotificationSidebarProps {
  visible: boolean;
  setFilterModalToggle: (visible: boolean) => void;
  notifications?: { today: NotificationMessage[]; older: NotificationMessage[] };
  loading: boolean;
  activeNotificationCount: number;
}

const NotificationSidebar = ({
  visible,
  setFilterModalToggle,
  notifications,
  loading,
  activeNotificationCount,
}: NotificationSidebarProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const showToast = (
    severity: 'success' | 'info',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const markAllAsReadMutation = useMutation({
    mutationFn: async () => {
      const response = await NotificationService.markAllAsRead();
      return response;
    },
    onSuccess: () => {
      showToast('success', 'marked_all_notifications_as_read', '');
      queryClient.invalidateQueries({
        queryKey: ['notifications_query'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications_query_sidebar'],
      });
    },
  });

  return (
    <Dialog
      header={
        <div className='text-lg font-semibold text-neutral-1 flex justify-between items-center'>
          <Translate value={'all_notifications'} />
        </div>
      }
      visible={visible}
      onHide={() => setFilterModalToggle(false)}
      draggable={false}
      className='w-full md:w-3/5 h-[95vh] md:h-[100vh] lg:w-[40%] max-h-[100%] m-0 notification-sidebar'
      style={{ position: 'fixed', bottom: 0, right: 0 }}
    >
      {loading && (
        <div className='w-full h-1 bg-primary'>
          <div className='h-full bg-white animate-pulse'></div>
        </div>
      )}
      <div className='px-6 py-4'>
        <div className='flex justify-end'>
          <Button
            link
            className='!text-transparent bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent text-small-semibold'
            onClick={() => {
              if (activeNotificationCount) {
                markAllAsReadMutation.mutate();
              } else {
                showToast('info', 'no_notification_to_mark_as_read', '');
              }
            }}
          >
            <Translate value='mark_all_as_read' />
          </Button>
          <div className='flex'>
            <Button
              link
              className='!text-transparent bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent text-small-semibold !pr-0'
              onClick={() => {
                navigate('/notification');
                setFilterModalToggle(false);
              }}
            >
              <Translate value='view_all' />
            </Button>
            <Button className='!p-0'>
              <ChevronRight />
            </Button>
          </div>
        </div>
        {Object.entries(notifications ?? {}).map(([key, value]) => (
          <div key={key}>
            {value.length ? (
              <div className='text-neutral-3 text-sm-semibold py-3'>
                <Translate value={key} />
              </div>
            ) : (
              ''
            )}
            {value.map((noticiation) => (
              <div
                className='flex gap-6 py-3 border-b border-b-2 border-neutral-surface-dark'
                key={noticiation.id}
              >
                <ContentTemplate
                  rowData={noticiation}
                  isSidebar
                  setFilterModalToggle={setFilterModalToggle}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default NotificationSidebar;
