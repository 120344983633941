import { CloseOutline } from '@carbon/icons-react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/translate';
import CurrencySelect from '../CurrencySelect';
import DropdownInput from '../DropdownInput';
import './index.css';

type FilterField = {
  type?: string;
  name: string;
  placeholder: string;
  value: string[];
  options: { label: string; value: string }[];
};

type FilterGroup = {
  header: string;
  fields: FilterField[];
};

interface FilterModalProps {
  visible: boolean;
  title: string;
  setFilterModalToggle: (visible: boolean) => void;
  filters: FilterGroup[];
  onApply: (filters: FilterFormData) => void;
  setFilters: React.Dispatch<React.SetStateAction<Array<FilterGroup>>>;
}

const FilterModal = ({
  visible,
  title,
  setFilterModalToggle,
  filters,
  onApply,
  setFilters,
}: FilterModalProps) => {
  const [formdata, setFormData] = useState<FilterFormData>({});

  useEffect(() => {
    const initialData: { [key: string]: string[] } = {};
    filters?.forEach((group) => {
      group.fields.forEach((field) => {
        initialData[field.name] = field.value || [];
      });
    });
    setFormData(initialData);
  }, [filters, visible]);

  const handleMultiSelectChange = (e: { value: string[] }, fieldName: string) => {
    setFormData({ ...formdata, [fieldName]: e.value });
  };

  const onReset = () => {
    const resetData = Object.keys(formdata).reduce((acc, key) => {
      const newVal = Array.isArray(formdata[key]) ? [] : '';
      return { ...acc, [key]: newVal };
    }, {});
    setFormData(resetData);
  };

  const applyFilter = (formData: any) => {
    setFilters((prevFilters: any) =>
      prevFilters.map((filter: any) => {
        const field = filter.fields[0];
        const formDataValue = formData[field.name] || (field.type !== 'single' ? [] : '');
        return {
          ...filter,
          fields: [
            {
              ...field,
              value: formDataValue,
            },
          ],
        };
      }),
    );
    const filteredFormData = Object.keys(formData).reduce((acc, key) => {
      if (formData[key].length) {
        return { ...acc, [key]: formData[key] };
      } else {
        return acc;
      }
    }, {});
    setFilterModalToggle(false);
    onApply(filteredFormData);
  };

  const selectedItemTemplate = (label: string, type: string, field: FilterField) => {
    if (!label) return null;
    if (type == 'currency')
      return (
        <div className='w-max flex items-center gap-[2px] bg-neutral-surface-dark pb-[1px] pt-[1px] pr-[8px] pl-[3px] h-[30px]  rounded-[16px]'>
          <img
            className='rounded-full w-[19px] h-[19px] object-cover inline-block'
            src={`/images/c_flags_svg/${label?.toLowerCase()}.svg`}
            alt={label || `${label} flag`}
          />
          <span className='text-xs-medium text-neutral-1 ml-1'>{label}</span>
          <CloseOutline onClick={() => removeItem(label)} />
        </div>
      );

    const _label = field.options.find((option) => option.value === label)?.label;
    return <Chip label={_label} className='mr-2' removable onRemove={() => removeItem(label)} />;
  };

  const itemTemplate = (option: { label: string; value: string }) => {
    return <div>{option.label}</div>;
  };

  const removeItem = (value: string) => {
    const updatedFormdata = { ...formdata };
    Object.keys(updatedFormdata).forEach((key) => {
      if (Array.isArray(updatedFormdata[key])) {
        updatedFormdata[key] = (updatedFormdata[key] as string[]).filter((item) => item !== value);
      }
    });
    setFormData(updatedFormdata);
  };

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='contrast' onClick={() => setFilterModalToggle(false)}>
        <Translate value='cancel' />
      </Button>
      <Button severity='info' onClick={onReset}>
        <Translate value='reset' />
      </Button>
      <Button severity='info' onClick={() => applyFilter(formdata)}>
        <Translate value='apply' />
      </Button>
    </div>
  );

  return (
    filters && (
      <Dialog
        className='w-full max-w-[400px] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        header={
          <div className='text-lg font-semibold text-neutral-1'>
            <Translate value={title} />
          </div>
        }
        visible={visible}
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        onHide={() => setFilterModalToggle(false)}
        footer={footerContent}
        draggable={false}
      >
        <Accordion multiple>
          {filters.map((group, index) => (
            <AccordionTab
              key={index}
              header={
                <div className='flex items-center gap-2'>
                  {group.header}
                  {formdata[group.fields[0].name] && formdata[group.fields[0].name].length > 0 && (
                    <Badge
                      value={
                        Array.isArray(formdata[group.fields[0].name])
                          ? formdata[group.fields[0].name].length
                          : 1
                      }
                      className='bg-primary'
                    ></Badge>
                  )}
                </div>
              }
            >
              <div>
                {group.fields.map((field) => {
                  switch (field.type) {
                    case 'currency': {
                      const currencyValue = formdata[field.name];
                      const currencyArrayValue = Array.isArray(currencyValue)
                        ? currencyValue
                        : currencyValue
                          ? [currencyValue]
                          : [];
                      return (
                        <CurrencySelect
                          key={field.name}
                          placeholder={field.placeholder}
                          multiSelectValue={currencyArrayValue}
                          className='p-2 border rounded-md'
                          isMulti={true}
                          onChange={(e: { value: string[] }) =>
                            handleMultiSelectChange(e, field.name)
                          }
                        />
                      );
                    }
                    case 'single': {
                      const singleValue = formdata[field.name];
                      const singleStringValue = Array.isArray(singleValue)
                        ? singleValue[0]
                        : singleValue;
                      return (
                        <DropdownInput
                          key={field.name}
                          value={singleStringValue}
                          onChange={(e) => handleMultiSelectChange(e, field.name)}
                          placeholder={field.placeholder}
                          options={field.options}
                        />
                      );
                    }
                    default: {
                      return (
                        <MultiSelect
                          key={field.name}
                          value={formdata[field.name] || []}
                          options={field.options}
                          onChange={(e) => handleMultiSelectChange(e, field.name)}
                          placeholder={field.placeholder}
                          className='px-2 border rounded-md w-full'
                          itemTemplate={itemTemplate}
                          selectedItemTemplate={(e: string) => {
                            return selectedItemTemplate(e, field.name, field);
                          }}
                          display='chip'
                        />
                      );
                    }
                  }
                })}
              </div>
            </AccordionTab>
          ))}
        </Accordion>
      </Dialog>
    )
  );
};

export default FilterModal;
