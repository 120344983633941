import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, type DialogProps } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import SearchDropDown from '../../../../components/SearchDropdown';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import ApprovalsSerive from '../approvalsService';
import './index.scss';
import RequestAppCount from './RequestAppCount';
import { useSelector } from 'react-redux';

type Props = {
  approvalDetails: ApprovalStructure;
  hideModal: () => void;
};

function ViewApproversDialog({ approvalDetails, hideModal, ...props }: DialogProps & Props) {
  const [updatedDetails, setUpdatedDetails] = useState<ApprovalStructure>(approvalDetails);

  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await ApprovalsSerive.updatePaymentApprovalStructure(updatedDetails);
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'success',
          detail: 'approvers.update.success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['approvals_list', approvalDetails.feature] });
      hideModal();
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'approvers.update.error',
          detail: error.message,
        }),
      );
    },
  });

  return (
    <Dialog
      visible
      draggable={false}
      className='max-w-[400px] w-full scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      header={
        <div className='grid gap-1 px-3'>
          <div className='text-lg font-semibold text-neutral-1'>
            <Translate value='approvals.view_approvers.heading' />
          </div>
          <div className='text-sm font-normal text-neutral-3'>
            <Translate
              value={
                approvalDetails.appStructure === 'GROUPED'
                  ? 'approvals.view_approvers.group_description'
                  : 'approvals.view_approvers.description'
              }
            />
          </div>
        </div>
      }
      headerClassName='border-b border-neutral-surface-subtle'
      {...props}
    >
      <div className='grid grid-rows-[1fr_auto] h-full overflow-hidden view-approvers-tabs pb-6'>
        {approvalDetails.appModelConfigs.length > 1 ? (
          <ViewApproversTabs
            approvalDetails={updatedDetails}
            updateDetails={(index, appModelConfig) =>
              setUpdatedDetails((prev) => ({
                ...prev,
                appModelConfigs: prev.appModelConfigs.map((config, i) =>
                  i === index ? appModelConfig : config,
                ),
              }))
            }
          />
        ) : (
          <ApproverForm
            appModelConfig={updatedDetails.appModelConfigs[0]}
            approverCategory={updatedDetails.approverCategory}
            feature={updatedDetails.feature}
            updateApprovers={(approvers) =>
              setUpdatedDetails((prev) => ({
                ...prev,
                appModelConfigs: prev.appModelConfigs.map((config) => ({
                  ...config,
                  appApprovers: approvers.map((approver) => ({
                    ...approver,
                    appConfigId: config.id,
                  })),
                })),
              }))
            }
            updateReqAppCount={(reqAppCount) => {
              setUpdatedDetails((prev) => ({
                ...prev,
                appModelConfigs: prev.appModelConfigs.map((config) => ({
                  ...config,
                  reqAppCount,
                })),
              }));
            }}
          />
        )}
        <div className='flex justify-end gap-2'>
          <Button severity='contrast' onClick={hideModal} className='h-12'>
            <Translate value='cancel' />
          </Button>
          <Button
            severity='info'
            disabled={updatedDetails.appModelConfigs.some(
              (config) => config.appApprovers.length === 0,
            )}
            onClick={() => mutation.mutate()}
            loading={mutation.isPending}
            className='w-48 h-12 justify-center items-center gap-2'
          >
            <Translate value='save_selection' />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function ViewApproversTabs({
  approvalDetails,
  updateDetails,
}: {
  approvalDetails: ApprovalStructure;
  updateDetails: (index: number, appModelConfigs: AppModelConfig) => void;
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      {approvalDetails.appModelConfigs.map((appModelConfig, index) => (
        <TabPanel
          key={index}
          header={
            <span>
              <Translate
                value={
                  approvalDetails.appStructure === 'GROUPED'
                    ? 'create_approval.group'
                    : 'create_approval.level'
                }
              />{' '}
              {index + 1}
            </span>
          }
        >
          <ApproverForm
            appModelConfig={appModelConfig}
            approverCategory={approvalDetails.approverCategory}
            feature={approvalDetails.feature}
            updateApprovers={(approvers) => {
              approvers.forEach((approver) => {
                approver.appConfigId = appModelConfig.id;
              });
              updateDetails(index, {
                ...appModelConfig,
                appApprovers: approvers,
              });
            }}
            updateReqAppCount={(reqAppCount) =>
              updateDetails(index, {
                ...appModelConfig,
                reqAppCount,
              })
            }
          />
        </TabPanel>
      ))}
    </TabView>
  );
}

type ApproverFormProps = {
  appModelConfig: AppModelConfig;
  approverCategory: 'USER' | 'ROLE';
  feature: FeatureType;
  updateApprovers: (approvers: Array<ApproverType>) => void;
  updateReqAppCount: (reqAppCount: number) => void;
};

function ApproverForm({
  appModelConfig,
  approverCategory,
  feature,
  updateApprovers,
  updateReqAppCount,
}: ApproverFormProps) {
  const selectedCustomer = useSelector(selectCurrentCustomer);

  const { isFetching, data } = useQuery({
    queryKey: [
      approverCategory === 'USER' ? 'getUsersByFeature' : 'getRolesByFeature',
      feature,
      selectedCustomer?.id,
    ],
    queryFn: async () => {
      if (approverCategory === 'USER') {
        const data = await ApprovalsSerive.getUsersByFeature(feature);
        return data;
      }

      const data = await ApprovalsSerive.getRolesByFeature(feature);
      return data;
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (!appModelConfig) {
    return <></>;
  }

  return (
    <div className='h-full flex flex-col gap-4 overflow-hidden'>
      <div className='flex pt-4 px-1 justify-between items-center'>
        <Translate
          value={
            approverCategory === 'USER'
              ? 'create_approval.set_approver_number'
              : 'create_approval.set_approver_number_roles'
          }
          className='text-neutral-1 text-sm-semibold'
        />
        <RequestAppCount
          reqAppCount={appModelConfig.reqAppCount}
          setReqAppCount={updateReqAppCount}
        />
      </div>
      {!data || data.length === 0 ? (
        <div>No data</div>
      ) : (
        <div className='flex-grow grid grid-rows-[aut_1fr] gap-4 overflow-hidden'>
          <SearchDropDown
            loading={isFetching}
            items={
              approverCategory === 'USER'
                ? (data as User[]).map((item) => ({
                    id: item.id as number,
                    label: item.userIdentity.firstName,
                    subLabel: item.userIdentity.email,
                  }))
                : (data as Role[]).map((item) => ({
                    id: item.id,
                    label: item.roleName,
                    subLabel: `${item.numberOfUsers || 0} users`,
                  }))
            }
            emptyMessage='no_results'
            selectedItems={appModelConfig.appApprovers.map((item) =>
              approverCategory === 'USER' ? (item.userId as number) : (item.roleId as number),
            )}
            onSelectChange={(ids) =>
              updateApprovers(
                ids.map((id) =>
                  approverCategory === 'USER'
                    ? { userId: id as number, roleId: null, userName: null }
                    : { roleId: id as number, userId: null, userName: null },
                ),
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export default ViewApproversDialog;
