import { PAYMENT_CATEGORY, PAYMENT_STATUS } from '../../types';

const PAYMENT_STATUS_OPTIONS = [
  {
    label: 'Created',
    value: PAYMENT_STATUS.CREATED,
  },
  {
    label: 'Pending Approval',
    value: PAYMENT_STATUS.PENDING_APPROVAL,
  },
  {
    label: 'Approval',
    value: PAYMENT_STATUS.APPROVAL,
  },
  {
    label: 'Committed',
    value: PAYMENT_STATUS.COMMITTED,
  },
  {
    label: 'Rejected',
    value: PAYMENT_STATUS.REJECTED,
  },
  {
    label: 'Deleted',
    value: PAYMENT_STATUS.DELETED,
  },
  {
    label: 'Compliance Failed',
    value: PAYMENT_STATUS.COMPLIANCE_FAILED,
  },
  {
    label: 'Pending Compliance',
    value: PAYMENT_STATUS.PENDING_COMPLIANCE,
  },
  {
    label: 'Abandoned',
    value: PAYMENT_STATUS.ABANDONED,
  },
];

const PAYMENT_CATEGORY_OPTIONS = [
  {
    label: 'Pending',
    value: PAYMENT_CATEGORY.PENDING,
  },
  {
    label: 'In Progress',
    value: PAYMENT_CATEGORY.IN_PROCESSING,
  },
  {
    label: 'Payment Completed',
    value: PAYMENT_CATEGORY.PAYMENT_COMPLETED,
  },
];

export { PAYMENT_CATEGORY_OPTIONS, PAYMENT_STATUS_OPTIONS };
