import { Home, Menu, Money, RequestQuote } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import WebFont from 'webfontloader';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import ClientService from '../../modules/Client/clientService';
import { dashboardRole } from '../../modules/Dashboard/role';
import { selectAoboClient, selectCurrentCustomer } from '../../store/slices/main/mainSlice';
import scrollEventEmitter from '../../utils/eventEmitter';
import { updateColorVariables } from '../../utils/helper';
import Header from '../Header';
import InactivityTimeout from '../InactivityTimeout';
import menuItems from '../menuItems';
import ScrollToTop from '../ScrollToTop';
import Sidebar from '../Sidebar';
import SibeBarMobileProps from '../SideBarMobile';
import './Layout.css';

function Layout() {
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const [brandingCompleted, setBrandingCompleted] = useState(false);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isMoreActionsOpen, setIsMoreActionsOpen] = useState(false);
  const navigate = useNavigate();
  const { hasRole } = useRole();

  useEffect(() => {
    if (selectedCustomer) {
      getBranding();
    }
  }, [selectedCustomer]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = (div: string) => {
    navigate('/');
    setTimeout(() => {
      scrollEventEmitter.emit('scrollTo', div);
    });
  };

  const getBranding = async () => {
    if (
      !selectedCustomer?.id ||
      selectedCustomer.id == 1 ||
      selectedCustomer.aobo ||
      window.state?.preview
    ) {
      setBrandingCompleted(true);
      return;
    }

    const branding = await ClientService.getCustomerBranding(selectedCustomer.id);
    const isClientExpandedLogoExist = branding?.find((b) => b.type === 'LOGOEXPANDED');
    const isClientCollapsedLogoExist = branding?.find((b) => b.type === 'LOGOCOLLAPSED');
    const isClientFavIconExist = branding?.find((b) => b.type === 'FAVICON');
    const iBrandingColorsExists = branding?.find((b) => b.type === 'BRANDINGCOLORS');

    if (isClientExpandedLogoExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'LOGOEXPANDED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.documentElement.style.setProperty('--client-logo-expanded', `url(${imageUrl})`);
        document.documentElement.style.setProperty(
          '--client-logo-expanded-dark',
          `url(${imageUrl})`,
        );
      }
    }

    if (isClientCollapsedLogoExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'LOGOCOLLAPSED');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.documentElement.style.setProperty('--client-logo-collapsed', `url(${imageUrl})`);
      }
    }
    if (isClientFavIconExist) {
      const response = await ClientService.donwload(selectedCustomer?.id, 'FAVICON');
      if (response) {
        const imageUrl = URL.createObjectURL(response);
        document.querySelector('link[rel="icon"]')?.setAttribute('href', imageUrl);
      }
    }

    if (iBrandingColorsExists) {
      const content = JSON.parse(iBrandingColorsExists.textContent);
      if (content.colorConfig && content.colorFormData) {
        updateColorVariables(content.colorConfig, content.colorFormData);
      }
      if (content.defaultFont) {
        WebFont.load({
          google: {
            families: [`${content.defaultFont.font}`],
          },
        });
        document.documentElement.style.setProperty('--font-family', content.defaultFont.font);
      }
    }

    setBrandingCompleted(true);
  };

  return (
    <>
      {brandingCompleted && (
        <div className='flex max-w-full h-screen bg-background'>
          <div className=' h-full'>
            {!isMobile && <Sidebar visible={sideBarVisible} setVisible={setSideBarVisible} />}
          </div>
          <div className='flex justify-evenly gap-8 items-center sm:hidden h-[50px] w-full bg-primary-dark text-white z-20 py-12 fixed  bottom-0'>
            <div className='flex flex-col items-center cursor-pointer'>
              <Menu
                size={24}
                onClick={() => {
                  setIsMoreActionsOpen(true);
                }}
              />
              <Translate value='menu' />
            </div>
            <div
              className='flex flex-col items-center'
              onClick={() => {
                handleScroll('requestQuoteDiv');
              }}
            >
              <RequestQuote size={24} />
              <Translate value='requestQuote' />
            </div>
            <div className='flex flex-col items-center' onClick={() => navigate('/')}>
              <Home size={24} />
              <Translate value='home' />
            </div>
            {hasRole(dashboardRole.payment) && (
              <div
                className='flex flex-col items-center'
                onClick={() => {
                  navigate('/payment/rate-requests');
                }}
              >
                <Money size={24} />
                <Translate value='payments' />
              </div>
            )}
          </div>
          <div
            className={`overflow-y-auto content ${
              !isMobile && (sideBarVisible ? 'content-shift' : 'content-collapsed')
            }`}
          >
            <Header />
            <div>
              <div
                className={`${selectedAoboClient ? 'h-[165px] lg:h-[118px]' : 'h-[78px]'}`}
              ></div>
              <div className='mb-[120px]'>
                <ScrollToTop />
                <Outlet />
              </div>
            </div>
          </div>
          {isMobile && isMoreActionsOpen && (
            <SibeBarMobileProps actions={menuItems} onClose={() => setIsMoreActionsOpen(false)} />
          )}
        </div>
      )}
    </>
  );
}

const RootLayout = () => {
  return (
    <>
      <InactivityTimeout />
      <Layout />
    </>
  );
};

export default RootLayout;
