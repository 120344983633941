import { ChevronRight, Close, OverflowMenuVertical } from '@carbon/icons-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import { sendToast } from '../../store/slices/main/mainSlice';

interface MoreActionMobileProps {
  actions: MenuItem[];
}

const MoreActionMobile = ({ actions }: MoreActionMobileProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { hasRole } = useRole();
  const dispatch = useDispatch();

  return (
    <div className='block sm:hidden'>
      <div
        className='block sm:hidden cursor-pointer'
        onClick={() => setIsMobileMenuOpen(true)} // Open the menu when clicked
      >
        <OverflowMenuVertical />
      </div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${isMobileMenuOpen ? 'block' : 'hidden'}`}
      >
        <div className='bg-white rounded-tr-lg rounded-tl-lg w-full max-w-lg p-4 shadow-lg absolute bottom-0'>
          <div className='flex justify-between items-center mb-4'>
            <h3 className='text-mobile-header-bold'>{/* <Translate value='Title' /> */}</h3>
            <button
              className='text-gray-500 hover:text-gray-700 text-xl font-bold'
              onClick={() => setIsMobileMenuOpen(false)} // Close the menu when clicked
            >
              <Close size={20} />
            </button>
          </div>
          <ul className='divide-y divide-neutral-border'>
            {actions.map(
              (item, index) =>
                (!item.roles || (item.roles && hasRole(item.roles))) && (
                  <li key={index} className='py-3 px-4'>
                    <div
                      className={
                        'flex justify-between items-center cursor-pointer hover:bg-gray-100 ' +
                        (item.className ?? 'text-neutral-2')
                      }
                      onClick={(event: React.MouseEvent) => {
                        if (item.disabled) {
                          dispatch(
                            sendToast({
                              severity: 'error',
                              summary: 'Warning',
                              detail: 'item.disabled.action',
                            }),
                          );
                          return;
                        }
                        if (item.onClick) {
                          item.onClick(event);
                        }
                        setIsMobileMenuOpen(false); // Close the menu after selecting an item
                        event.stopPropagation();
                      }}
                    >
                      <div className='flex items-center space-x-3'>
                        <div className='text-neutral-3'>{item.icon}</div>
                        <span className='text-md-semibold p-menuitem-link'>
                          <Translate value={item.label} />
                        </span>
                      </div>
                      <div>
                        <ChevronRight size={20} />
                      </div>
                    </div>
                  </li>
                ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MoreActionMobile;
