import type { SortOrder } from 'primereact/datatable';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/report-management';
const ORDER_MANAGEMENT = '/api/order-management';

const ReportsService = {
  async getReports(
    sortField: string,
    sortOrder: SortOrder,
    page: number,
    rows: number,
    reportTypes: Array<string>,
  ) {
    const response = await apiClient.post(
      `${ROOT}/payment-reports?page=${page}&size=${rows}&sortBy=${sortField}&direction=${sortOrder === 1 ? 'asc' : 'desc'}`,
      { reportTypes },
    );
    return handleApiResponse<{
      content: Array<ReportType>;
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      totalPages: number;
    }>(response);
  },

  async downloadReport(reportId: string, fileName: string) {
    const response = await apiClient.get(`${ORDER_MANAGEMENT}/reports/download/recon/${reportId}`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },
};

export default ReportsService;
