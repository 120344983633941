import { Close, InformationFilled } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RawHtmlContent, Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectAoboClient,
  selectCurrentCustomer,
  selectHierarchyChildren,
  sendToast,
  setAoboClient,
  setAoboClientFilterType,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import { injectAoboCustoemrID } from '../../utils/apiClient';
import { AoboDropdown } from './AoboDropdown';

const AoboHeader = () => {
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const hierarchyChildren = useSelector(selectHierarchyChildren);
  const [aoboLabel, setAOoboLabel] = useState<string | undefined>(selectedAoboClient?.label);
  const navigate = useNavigate();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const exitAobo = () => {
    injectAoboCustoemrID(null);
    showToast('success', 'aobo.remove.title', 'aobo.remove.description');
    dispatch(fethCurrentUser());
    dispatch(setCurrentCustomer(parentClient));
    dispatch(setAoboClient(undefined));
    dispatch(setAoboClientFilterType(undefined));
    navigate('/');
  };

  useEffect(() => {
    const client = hierarchyChildren?.find((c) => c.id == selectedAoboClient?.value)?.parent;
    setAOoboLabel(
      `${client?.customerShortName ?? client?.customerLegalName}> ${selectedAoboClient?.label}`,
    );
  }, [selectedAoboClient, hierarchyChildren]);

  if (!selectedAoboClient) {
    return <></>;
  }

  return (
    <div className='flex justify-between flex-col sm:flex-row text-16-bold p-1 border border-warning-border-subtle bg-warning-surface-light align-middle py-[2] px-[20px] sm:px-[49px]'>
      <div className='flex justify-start items-start md:items-center flex-row sm:flex-col md:flex-row'>
        <div className='flex justify-start'>
          <div className='mr-1'>
            <InformationFilled fill='var(--warning-content-default)' size={21} />
          </div>
          <div className='text-warning-content-dark text-sm-regular'>
            <RawHtmlContent
              htmlContent='aobo.warning.header'
              translateValues={{ clientName: aoboLabel }}
            />
          </div>
        </div>
        <div className='ml-2 hidden sm:block'>
          <Button
            severity='secondary'
            className='border !border-warning-bg min-w-28 align-middle !h-[32px] !p-0'
            onClick={() => {
              exitAobo();
            }}
          >
            <Translate value='aobo.exit' className='text-error-1 m-auto' />
          </Button>
        </div>
        <div className='ml-2 block sm:hidden'>
          <Close
            onClick={() => {
              exitAobo();
            }}
          />
        </div>
      </div>
      <div className='flex items-center'>
        <AoboDropdown fromAoboHeader={true} />
      </div>
    </div>
  );
};

export default AoboHeader;
