import { TabPanel, TabView } from 'primereact/tabview';
import React, { ReactNode, useEffect, useState } from 'react';
import { Translate } from '../../i18n/translate';
import './index.css';

interface Tab {
  header: string;
  content: ReactNode;
  isDisabled?: boolean;
  loading?: boolean;
}

interface CustomTabViewProps {
  tabs: Tab[];
  initialIndex?: number;
  updateActiveIndex?: (activeIndex: number) => void;
}

const CustomTabView: React.FC<CustomTabViewProps> = ({
  tabs,
  initialIndex = 0,
  updateActiveIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  // Update the activeIndex whenever the initialIndex changes
  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => {
        if (updateActiveIndex) {
          updateActiveIndex(e.index);
        }
        setActiveIndex(e.index);
      }}
      className='tab-view'
    >
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          header={<Translate value={tab.header} />}
          disabled={tab.isDisabled || false}
          rightIcon={activeIndex == index && tab.loading ? 'pi pi-spin pi-spinner ml-1' : undefined}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabView>
  );
};

export default CustomTabView;
