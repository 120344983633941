import { ButtonProps } from 'primereact/button';
import { classNames } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ButtonConf: any = {
  button: {
    root: ({ props, context }: { props: ButtonProps; context: any }) => ({
      className: classNames(
        'items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom',
        'transition duration-200 ease-in-out',
        'focus:!outline-none focus:!outline-offset-0 focus:shadow-none',
        {
          'text-white dark:text-gray-900 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 dark:hover:bg-blue-500 hover:border-blue-600 dark:hover:border-blue-500 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'text-blue-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
            props.link,
        },
        { 'text-sm-semibold px-[14px] py-[8px] rounded-c8': props.icon == null },
        {
          'text-white dark:text-gray-900 bg-gray-500 dark:bg-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-600 dark:hover:bg-gray-500 hover:border-gray-600 dark:hover:border-gray-500':
            props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
          'bg-gradient-to-b from-primary-gradient-to to-primary-gradient-from bg-clip-text text-transparent dark:text-gray-900 dark:bg-gray-400 border border-primary-dark-content hover:bg-gray-600 hover:border-gray-600':
            props.severity === 'contrast' &&
            !props.text &&
            !props.outlined &&
            !props.plain &&
            !context.disabled,
          'text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500':
            props.severity === 'success' && !props.text && !props.outlined && !props.plain,
          'text-white dark:text-gray-900 dark:bg-blue-400 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 hover:border-blue-600 dark:hover:bg-blue-500 dark:hover:border-blue-500':
            props.severity === 'info' && !props.text && !props.outlined && !props.plain,
          'text-error-1 dark:text-gray-900 bg-orange-500 dark:bg-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:border-orange-600 dark:hover:border-orange-500':
            props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
          'text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500':
            props.severity === 'help' && !props.text && !props.outlined && !props.plain,
          'text-white dark:text-gray-900 bg-error-1 dark:bg-red-400 border border-error-1 dark:border-red-400 hover:bg-red-600 dark:hover:bg-error-1 hover:border-red-600 dark:hover:border-error-1':
            props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
        },
        { 'shadow-lg': props.raised },
        { 'rounded-lg': !props.rounded, 'rounded-full': props.rounded },
        {
          'bg-transparent border-transparent': props.text && !props.plain,
          'text-blue-500 dark:text-blue-400 hover:bg-blue-300/20':
            props.text && (props.severity === null || props.severity === 'info') && !props.plain,
          'text-gray-500 dark:text-grayy-400 hover:bg-gray-300/20':
            props.text && props.severity === 'secondary' && !props.plain,
          'text-green-500 dark:text-green-400 hover:bg-green-300/20':
            props.text && props.severity === 'success' && !props.plain,
          'text-orange-500 dark:text-orange-400 hover:bg-orange-300/20':
            props.text && props.severity === 'warning' && !props.plain,
          'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20':
            props.text && props.severity === 'help' && !props.plain,
          'text-red-500 dark:text-red-400 hover:bg-red-300/20':
            props.text && props.severity === 'danger' && !props.plain,
        },
        { 'shadow-lg': props.raised && props.text },
        {
          'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
          'text-gray-500 border border-gray-500 hover:bg-gray-300/20':
            props.plain && props.outlined,
          'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600':
            props.plain && !props.outlined && !props.text,
        },
        {
          'bg-transparent border': props.outlined && !props.plain,
          'text-blue-500 dark:text-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-300/20':
            props.outlined &&
            (props.severity === null || props.severity === 'info') &&
            !props.plain,
          'text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-300/20':
            props.outlined && props.severity === 'secondary' && !props.plain,
          'text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20':
            props.outlined && props.severity === 'success' && !props.plain,
          'text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20':
            props.outlined && props.severity === 'warning' && !props.plain,
          'text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20':
            props.outlined && props.severity === 'help' && !props.plain,
          'text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20':
            props.outlined && props.severity === 'danger' && !props.plain,
        },
        {
          'px-4 py-3 text-base': props.size === null,
          'text-xs py-2 px-3': props.size === 'small',
          'text-xl py-3 px-4': props.size === 'large',
        },
        { 'flex-column': props.iconPos == 'top' || props.iconPos == 'bottom' },
        {
          'bg-neutral-4 text-white opacity-100 pointer-events-none':
            context.disabled && (props.severity == 'info' || props.severity == 'contrast'),
        },
        {
          'bg-primary text-primary-text hover:bg-primary-hover hover:shadow-none':
            props.severity === 'info' && props.icon == null,
        },
        {
          'bg-primary-disabled text-primary-text':
            props.severity === 'info' && props.icon == null && context.disabled,
        },
        {
          '!bg-secondary !text-secondary-text border-secondary-border hover:bg-secondary-hover hover:shadow-c':
            props.severity === 'secondary' && props.icon == null,
        },
        {
          'hover:bg-secondary-hover hover:shadow-c':
            props.severity === 'contrast' && props.icon == null,
        },
        { 'border-none !p-0': props.icon && props.label == null },
      ),
    }),
    label: ({ props }: { props: ButtonProps }) => ({
      className: classNames(
        'flex-1',
        'duration-200',
        'font-bold',
        {
          'hover:underline': props.link,
        },
        { 'invisible w-0': props.label == null },
      ),
    }),
    icon: ({ props }: { props: ButtonProps }) => ({
      className: classNames('mx-0', {
        'mr-2': props.iconPos == 'left' && props.label != null,
        'ml-2 order-1': props.iconPos == 'right' && props.label != null,
        'mb-2': props.iconPos == 'top' && props.label != null,
        'mt-2 order-2': props.iconPos == 'bottom' && props.label != null,
      }),
    }),
    loadingIcon: ({ props }: { props: ButtonProps }) => ({
      className: classNames('mx-0', {
        'mr-2': props.loading && props.iconPos == 'left' && props.label != null,
        'ml-2 order-1': props.loading && props.iconPos == 'right' && props.label != null,
        'mb-2': props.loading && props.iconPos == 'top' && props.label != null,
        'mt-2 order-2': props.loading && props.iconPos == 'bottom' && props.label != null,
      }),
    }),
    badge: ({ props }: { props: ButtonProps }) => ({
      className: classNames({
        'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge,
      }),
    }),
  },
};

export default ButtonConf;
