import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import WebFont from 'webfontloader';

const Preview = () => {
  const naviget = useNavigate();
  useEffect(() => {
    const branding = localStorage.getItem('branding');
    if (branding) {
      const { colorConfig, colorFormData, images, defaultFont } = JSON.parse(branding);
      preview(colorConfig, colorFormData);
      WebFont.load({
        google: {
          families: [`${defaultFont.font}`],
        },
      });
      document.documentElement.style.setProperty('--font-family', defaultFont.font);
      if (images) {
        if (images.logoCollapsed) {
          document.documentElement.style.setProperty(
            '--client-logo-collapsed',
            `url(${images.logoCollapsed})`,
          );
        }
        if (images.logoExpanded) {
          document.documentElement.style.setProperty(
            '--client-logo-expanded',
            `url(${images.logoExpanded})`,
          );
          document.documentElement.style.setProperty(
            '--client-logo-expanded-dark',
            `url(${images.logoExpanded})`,
          );
        }
        if (images.favIcon) {
          document.querySelector('link[rel="icon"]')?.setAttribute('href', images.favIcon);
        }
      }
    }
    naviget('/');
  }, []);

  const preview = (colorConfig: ColorConfig[], colors: ColorsFormData) => {
    colorConfig.forEach(({ name, var: cssVar }) => {
      let colorValue = colors[name];
      if (colorValue) {
        if (!colorValue.startsWith('#')) {
          colorValue = '#' + colorValue;
        }
        document.documentElement.style.setProperty(cssVar as string, colorValue);
      }
    });
  };
  return (
    <>
      <div>i</div>
    </>
  );
};

export default Preview;
