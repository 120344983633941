import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTabView } from '../../components';
import useRole from '../../hooks/useRoles';
import { Translate, translateWithValues } from '../../i18n/translate';
import { PageHeadContext } from '../../providers/PageHead';
import { selectLoading, selectOktaUser } from '../../store/slices/main/mainSlice';
import scrollEventEmitter from '../../utils/eventEmitter';
import PaymentsTable from '../Payment/RateRequest/Dashboard/PaymentsTable';
import PendingTaskWidget from './PendingTaskWidget';
import QuickQuoteForm from './QuickQuoteForm';
import { dashboardRole } from './role';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const oktaUser = useSelector(selectOktaUser);
  const { hasRole } = useRole();
  const paymentsTableRef = useRef<HTMLDivElement | null>(null);
  const isLoading = useSelector(selectLoading);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      loading: isLoading,
      header: `${translateWithValues('pending_payment_tab')} ${activeIndex === 0 && !isLoading ? totalRecords : ''}`,
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='PENDING'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
        />
      ),
    },
    {
      loading: isLoading,
      header: `${translateWithValues('pending_execution')} ${activeIndex === 1 && !isLoading ? totalRecords : ''}`,
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='IN_PROCESSING'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
        />
      ),
    },
    {
      loading: isLoading,
      header: `${translateWithValues('completed')} ${activeIndex === 2 && !isLoading ? totalRecords : ''}`,
      content: (
        <PaymentsTable
          isDashboard={true}
          filterCategory='PAYMENT_COMPLETED'
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}
        />
      ),
    },
  ];

  useEffect(() => {
    setPageHeadData({
      title: 'welcome',
      description: 'dashboard.description',
      className: 'pb-[16px] pr-4',
      params: { userName: oktaUser?.name },
    });
  }, [setPageHeadData]);

  useEffect(() => {
    const handleScroll = (id: string) => {
      if (id === 'paymentsDiv' && paymentsTableRef.current) {
        paymentsTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    scrollEventEmitter.on('scrollTo', handleScroll);

    return () => {
      scrollEventEmitter.off('scrollTo', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className='px-[20px] sm:px-[49px]'>
        <div className='flex flex-col lg:flex-row gap-8 mt-8'>
          <QuickQuoteForm />
          {hasRole(dashboardRole.PendingWidgetRole) && <PendingTaskWidget />}
        </div>
        {hasRole(dashboardRole.payment) && (
          <div className='card mt-8' ref={paymentsTableRef}>
            <div className='text-lg-semibold'>
              <Translate value='all_payments' />
            </div>
            <CustomTabView tabs={tabs} initialIndex={0} updateActiveIndex={setActiveIndex} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
