import { LoginCallback } from '@okta/okta-react';
import { Suspense, lazy, useEffect } from 'react';
import { createBrowserRouter, useNavigate } from 'react-router-dom';
import { RequireAuth } from './auth/Okta';
import { ErrorPage, RoleGuard, Unauthorized } from './components';
import { RouteRoles } from './RouteRoles';

// Lazy load the components
const RootLayout = lazy(() => import('./components/Layout'));
const ClientOutlet = lazy(() => import('./modules/Client'));
const ReportsOutlet = lazy(() => import('./modules/Reports'));
const ConfigurationOutlet = lazy(() => import('./modules/Configuration'));
const ManageOutlet = lazy(() => import('./modules/Manage'));
const Preview = lazy(() => import('./components/Preview'));
const PaymentOutelet = lazy(() => import('./modules/Payment'));

import { createAction } from '@reduxjs/toolkit';
import { Translate } from './i18n/translate';
import clientRoutes from './modules/Client/routes';
import reportsRoutes from './modules/Reports/routes';
import configurationRoutes from './modules/Configuration/routes';
import Dashboard from './modules/Dashboard';
import manageRoutes from './modules/Manage/routes';
import NotificationOutlet from './modules/Notification';
import notificationRoutes from './modules/Notification/routes';
import paymentRoutes from './modules/Payment/routes';
import { PageHeadProvider } from './providers/PageHead';
import { useAppDispatch } from './store';

const LogoutRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resetSessionState = createAction('session/resetState');

  useEffect(() => {
    dispatch(resetSessionState());
    navigate('/');
  }, [navigate]);

  return null;
};

const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <Suspense fallback={<Translate value='loading' />}>
          <RootLayout />
        </Suspense>
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: (
          <PageHeadProvider>
            <Dashboard />
          </PageHeadProvider>
        ),
      },
      {
        path: 'client',
        element: (
          <RoleGuard roles={RouteRoles.client}>
            <Suspense fallback={<Translate value='loadingClient' />}>
              <ClientOutlet />
            </Suspense>
          </RoleGuard>
        ),
        handle: {
          breadcrumb: 'Client Management',
        },
        children: clientRoutes,
      },
      {
        path: 'reports',
        element: (
          <Suspense fallback={<Translate value='loadingReports' />}>
            <ReportsOutlet />
          </Suspense>
        ),
        handle: {
          breadcrumb: 'Reports',
        },
        children: reportsRoutes,
      },
      {
        path: 'manage',
        handle: {
          breadcrumb: 'Manage',
        },
        element: (
          <RoleGuard roles={RouteRoles.manage}>
            <Suspense fallback={<Translate value='loadingManage' />}>
              <ManageOutlet />
            </Suspense>
          </RoleGuard>
        ),
        children: manageRoutes,
      },
      {
        path: 'configuration',
        element: (
          <RoleGuard roles={RouteRoles.configuration}>
            <Suspense fallback={<Translate value='loadingConfiguration' />}>
              <ConfigurationOutlet />
            </Suspense>
          </RoleGuard>
        ),
        children: configurationRoutes,
      },
      {
        path: 'payment',
        handle: {
          breadCrumb: 'Payments',
        },
        element: (
          <RoleGuard roles={RouteRoles.payment}>
            <Suspense fallback={<Translate value='loadingPayments' />}>
              <PaymentOutelet />
            </Suspense>
          </RoleGuard>
        ),
        children: paymentRoutes,
      },
      {
        path: 'notification',
        element: <NotificationOutlet />,
        handle: {
          breadcrumb: 'Notifications',
        },
        children: notificationRoutes,
      },
    ],
  },
  {
    path: 'login/callback',
    element: <LoginCallback />,
  },
  {
    path: 'logout/callback',
    element: <LogoutRedirect />,
  },
  {
    path: 'preview',
    element: (
      <Suspense fallback={<Translate value='loadingPreview' />}>
        <Preview />
      </Suspense>
    ),
  },
  {
    path: 'unauthorized',
    element: <Unauthorized />,
  },
]);

export default routes;
