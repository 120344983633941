import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { Translate } from '../../../../i18n/translate';
import RadioButton from '../../../../icons/RadioButton';
import { AddOrEditOrView } from '../../../../types/enum';

export const FontDetails = ({
  mode,
  defaultFont,
  setdefaultFont,
}: {
  mode: AddOrEditOrView;
  defaultFont: FontDetail;
  setdefaultFont: (font: FontDetail) => void;
}) => {
  const [isFontsVisible, setIsFontVisible] = useState(false);
  const fonts: FontDetail[] = [
    { name: 'Default Font Style', font: 'Inter', weight: '400' },
    { name: 'Roboto', font: '"Roboto", sans-serif', weight: '400' },
    { name: 'Open Sans', font: 'Open Sans', weight: '400' },
    { name: 'Helvetica Neue', font: 'Helvetica Neue', weight: '400' },
    { name: 'Lato', font: 'Lato', weight: '400' },
    { name: 'Arial Narrow', font: 'Arial Narrow', weight: '400' },
  ];

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts.map((font) => `${font.font}`),
      },
    });
  }, []);

  const handleFontSelect = (font: FontDetail) => {
    setdefaultFont(font);
  };

  return (
    <div>
      <div className='shadow-c w-full rounded-c8 py-8 px-4 sm:px-20 gap-4'>
        <div className='text-md-semibold'>
          <Translate value='set_font_combinations' />
        </div>
        {isFontsVisible || mode === AddOrEditOrView.View ? (
          <div>
            <div className='grid grid-cols-2 sm:grid-cols-3 gap-5'>
              {fonts.map((fontOption) => (
                <div
                  key={fontOption.font}
                  className={`shadow-c p-4 rounded-lg mb-4 transition-all duration-200'}
            hover:shadow-xl cursor-pointer`}
                  style={{ fontFamily: fontOption.font }}
                  onClick={() => {
                    if (mode !== AddOrEditOrView.View) {
                      handleFontSelect(fontOption);
                    }
                  }}
                >
                  <div className='flex justify-between items-center mb-4'>
                    <h3 className='text-lg font-medium'>{fontOption.name}</h3>
                    {defaultFont.font === fontOption.font ? (
                      <RadioButton isdisabled={false} />
                    ) : (
                      <RadioButton isdisabled={true} />
                    )}
                  </div>
                  <p style={{ fontWeight: fontOption.weight }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.{' '}
                  </p>
                </div>
              ))}
            </div>
            {mode !== AddOrEditOrView.View && (
              <div className='pt-8 text-center'>
                <Button
                  severity='contrast'
                  className='!px-14'
                  onClick={() => {
                    setIsFontVisible(false);
                  }}
                >
                  <Translate value='return' />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <>
            <div>
              <Button
                severity='contrast'
                className='!px-14'
                onClick={() => {
                  setIsFontVisible(true);
                }}
              >
                <Translate value='start' />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
