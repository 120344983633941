import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownInput, TextInput } from '../../../../components';
import CountrySelect from '../../../../components/CountrySelect';
import { Translate } from '../../../../i18n/translate';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { CustomerSubType, CustomerType, KycStatus } from '../../../../types';
import { BANK_TYPE_OPTIONS, EMAIL_VALIDATION_REGEX } from '../../../Client/clientConstants';
import PaymentService from '../../paymentService';

interface CustomerCreationProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  isViewMode?: boolean;
  handleNewCustomer?: (customer: Client) => void;
  handleDeleteEntry?: () => void;
  defaultValues?: Omit<CustomerCreationForm, 'kycStatus'>;
}

const CustomerCreationModal = ({
  visible,
  setVisible,
  isViewMode = false,
  handleNewCustomer = () => null,
  handleDeleteEntry = () => null,
  defaultValues,
}: CustomerCreationProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomerCreationForm>({
    defaultValues: isViewMode ? defaultValues : undefined,
  });
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const saveCustomerDetails = async (customerData: CustomerCreationForm) => {
    const payload: CustomerCreationForm = {
      ...customerData,
      customerType: CustomerType.ORDERING_CUSTOMER,
      owningCustomerId: selectedCustomer?.id,
      kycStatus: KycStatus.ACTIVE,
      customerShortName: customerData.customerLegalName,
      customerSubType: CustomerSubType.CONSUMER,
    };
    payload.address.country = customerData.address.country?.code;
    const { data, status } = await PaymentService.createNewCustomer(payload);
    if (status !== 200) {
      showToast('error', 'error', 'client.creation.error.toast.desc');
      return;
    } else {
      const { result } = data;
      if (result.success) {
        showToast('success', 'success', 'quote.created.new.customer.success');
        handleNewCustomer(data.data);
        setVisible(false);
      } else {
        showToast('error', 'error', result.errors[0]?.errorTextCode, payload as any);
      }
    }
  };

  return (
    <Dialog
      className='w-full md:w-3/5 max-h-[100%] m-0 !rounded-none full-screen-dialog'
      header={
        <>
          <div className='text-lg font-semibold text-neutral-1'>
            <Translate value={isViewMode ? 'customer.view.header' : 'add_new_customer.header'} />
          </div>
          {!isViewMode ? (
            <div className='text-sm-regular mt-1 text-neutral-3'>
              <Translate value='add_new_customer.subtitle' />
            </div>
          ) : null}
        </>
      }
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(saveCustomerDetails)} className='mt-10'>
        <fieldset disabled={isViewMode}>
          <div className='grid grid-cols-[2fr_1fr] gap-6'>
            <TextInput
              label='customer_name'
              placeholder='customer_name'
              isRequired
              formRegister={register('customerLegalName', { required: true })}
              error={errors.customerLegalName}
            />
            <TextInput
              label='customer_identifier'
              placeholder='customer_identifier'
              formRegister={register('customerIdentifier')}
              error={errors.customerIdentifier}
            />
          </div>
          <div className='mt-6 grid grid-cols-2 gap-6'>
            <TextInput
              label='address_line_1'
              placeholder='address_line_1'
              isRequired
              formRegister={register('address.street1', { required: true })}
              error={errors.address?.street1}
            />
            <TextInput
              label='address_line_2'
              placeholder='address_line_2'
              formRegister={register('address.street2')}
              error={errors.address?.street2}
            />
          </div>
          <div className='mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            <TextInput
              label='city'
              placeholder='city'
              isRequired
              formRegister={register('address.city', { required: true })}
              error={errors.address?.city}
            />
            <TextInput
              label='state_or_region'
              placeholder='state_or_region'
              isRequired
              formRegister={register('address.state', { required: true })}
              error={errors.address?.state}
            />
            <TextInput
              label='zip_code'
              placeholder='zip_code'
              isRequired
              formRegister={register('address.postalCode', { required: true })}
              error={errors.address?.postalCode}
            />
            <CountrySelect
              label='country'
              placeholder='country'
              control={control}
              name='address.country'
              error={errors.address?.country}
              disabled={isViewMode}
            />
            <TextInput
              label='phone'
              placeholder='phone'
              isRequired
              formRegister={register('phone', { required: true })}
              error={errors.phone}
            />
            <TextInput
              label='email_id'
              placeholder='email_id'
              isRequired
              formRegister={register('email', {
                required: true,
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: 'invalid_email_address',
                },
              })}
              error={errors.email}
            />
          </div>
          <div className='mt-6 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 border-t border-t-2 border-neutral-border-2'>
            <TextInput
              label='account_number'
              placeholder='account_number'
              control={control}
              isRequired
              formRegister={register('bankInfos.0.accountNumber', { required: true })}
              error={errors.bankInfos?.[0]?.accountNumber}
            />
            <DropdownInput
              label='bank_code_type'
              placeholder='bank_code_type'
              options={BANK_TYPE_OPTIONS}
              control={control}
              name='bankInfos.0.codeType'
              rules={{ required: true }}
              isRequired
              error={errors.bankInfos?.[0]?.codeType}
              disabled={isViewMode}
            />
            <TextInput
              label='bank_code'
              placeholder='bank_code'
              isRequired
              formRegister={register('bankInfos.0.routingCode', { required: true })}
              error={errors.bankInfos?.[0]?.routingCode}
            />
          </div>
          {!isViewMode ? (
            <div className='flex justify-center mt-14 gap-6'>
              <Button
                severity='contrast'
                type='button'
                onClick={() => {
                  reset();
                  setVisible(false);
                  handleDeleteEntry();
                }}
              >
                <Translate value='delete_entry' />
              </Button>
              <Button type='submit' severity='info' className='!px-10'>
                <Translate value='save' />
              </Button>
            </div>
          ) : null}
        </fieldset>
      </form>
    </Dialog>
  );
};

export default CustomerCreationModal;
