import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Translate } from '../../../../i18n/translate';
import { Button } from 'primereact/button';
import { useQuery } from '@tanstack/react-query';
import PaymentService from '../../paymentService';
import { Checkmark } from '@carbon/icons-react';
import { convertCalenderDateFormat, convertToTimeFormat } from '../../../../utils/helper';
import './index.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

interface PaymentHistoryProps {
  showHistory: boolean;
  setShowHistory: (_booleanis: boolean) => void;
  orderId: number | null;
}

const PaymentHistory = ({ showHistory, setShowHistory, orderId }: PaymentHistoryProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [`payment_history_${orderId}`, showHistory],
    queryFn: async (): Promise<PaymentHistory[] | null> => {
      if (showHistory) {
        const data = await PaymentService.getPaymentHistory(orderId ?? 0);
        return data;
      } else {
        return null;
      }
    },
  });

  return (
    <Dialog
      draggable={false}
      className='payment-history-sidebar w-full max-w-[400px] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={
        <div className='flex flex-col gap-1'>
          <div className='text-sm-bold'>
            <Translate value='event.history' />
          </div>
          <div>
            <Translate value='payment_id' className='text-neutral-3 text-sm-regular' />{' '}
            <span className='text-selected'>#{orderId}</span>
          </div>
        </div>
      }
      visible={showHistory}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setShowHistory(false)}
      footer={
        <Button severity='secondary' onClick={() => setShowHistory(false)}>
          <Translate value='cancel' />
        </Button>
      }
    >
      {isLoading && (
        <div className='absolute z-10 inset-0 bg-white/50 grid place-content-center'>
          <ProgressSpinner className='w-10 h-10' />
        </div>
      )}
      <div>
        <div className='mt-10'>
          {data?.map((history, index) => (
            <div key={index} className='grid grid-cols-[3fr_1fr_7fr] mb-1 relative'>
              <div className='text-right mr-4 pb-6'>
                <div> {convertCalenderDateFormat(history.eventTime)}</div>
                <div> {convertToTimeFormat(history.eventTime)} </div>
              </div>
              <div className='relative min-w-[40px]'>
                <div className='absolute top-0 left-[0.87rem] h-full bg-primary w-[0.214rem]' />
                <div className='relative bg-primary w-8 h-8 flex items-center justify-center rounded-full'>
                  <Checkmark color='white' size={18} />
                </div>
              </div>
              <div className='flex-1 pb-8'>
                <div className='text-selected text-sm-semibold'>
                  <Translate value={history.eventType} />
                </div>
                <div>{history.userName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentHistory;
