import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import translations from './translations.json';
import paymentCodes from './paymentCodes.json';
import { Messages } from './types';

interface I18nProviderProps {
  children: ReactNode;
  locale: string;
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children, locale }) => {
  const [messages, setMessages] = useState<Messages>({});

  useEffect(() => {
    const loadMessages = () => {
      const loadedMessages = (translations as Record<string, Messages>)[locale];
      const paymentCodesTranslations = (paymentCodes as Record<string, Messages>)[locale];
      loadedMessages && Object.assign(loadedMessages, paymentCodesTranslations);
      setMessages(loadedMessages);
    };

    loadMessages();
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={messages} onError={intlProviderErrorHandler}>
      {children}
    </IntlProvider>
  );
};

const intlProviderErrorHandler = (error: any) => {
  if (error.code === 'MISSING_TRANSLATION') {
    return;
  }
};

export default I18nProvider;
