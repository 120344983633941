import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CountrySelect,
  CurrencySelect,
  PaginatorTemplate,
  TextInput,
} from '../../../../components';
import getCountryISO2 from '../../../../components/CountrySelect/isoncCodeMapping';
import InfoTemplate from '../../../../components/InfoTemplate';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { Search } from '../../../../icons';
import { selectCountries } from '../../../../store/slices/refdata/refdataSlice';
import PaymentService from '../../paymentService';

interface BranchLookupProps {
  fieldLabelIdentifier: string;
  fieldLabel: string;
  onBicCodeSelect: (bicCodeData: Record<string, string>) => void;
  buyCurrency: string;
  countries: Array<string>;
  closeModal: () => void;
}

const BranchLookupModal = ({
  fieldLabelIdentifier,
  fieldLabel,
  onBicCodeSelect,
  buyCurrency,
  countries,
  closeModal,
}: BranchLookupProps) => {
  const selectedCountries = useSelector(selectCountries);

  const countryList = useMemo(() => {
    return countries.reduce((acc, code) => {
      const country = selectedCountries.find((item) => item.code === code);
      return country ? [...acc, country] : acc;
    }, [] as Array<Country>);
  }, [countries]);

  const [bicCode, setBicCode] = useState('');
  const [city, setCity] = useState('');
  const [bankName, setBankName] = useState('');
  const [country, setCountry] = useState(countryList.length ? countryList[0] : undefined);

  const isSwiftBicCode =
    fieldLabelIdentifier === 'SwiftBICCode' ||
    fieldLabelIdentifier === 'IntermediaryBankSwiftBICCode';

  const branchDataQuery = useQuery({
    queryKey: ['branch_data', country?.code, fieldLabelIdentifier, bicCode, city, bankName],
    queryFn: async () => {
      const countryCode = getCountryISO2(country!.code);
      const data = isSwiftBicCode
        ? await PaymentService.getBICCode(countryCode, bicCode, city, bankName)
        : await PaymentService.getBankDirectory(countryCode, bicCode, city, bankName);
      return data;
    },
    enabled: !!country && !!(bicCode || city || bankName),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const tableData = branchDataQuery.data?.map((item) => ({
    ...item,
    table_column_city: isSwiftBicCode ? item.OpCity || item.ReCity : item.City,
    table_column_address: isSwiftBicCode
      ? item.OpStreetAddress1 || item.ReStreetAddress1 || item.OpCity || item.ReCity
      : item.StreetAddress1 || item.City,
  }));

  return (
    <div className='h-full overflow-hidden flex flex-col pb-5'>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-5 py-2'>
        <CurrencySelect
          label='currency'
          placeholder='currency'
          isRequired
          disabled
          value={buyCurrency}
        />
        <CountrySelect
          label='beneficiary_country'
          placeholder='beneficiary_country'
          isRequired
          countryList={countryList}
          onChange={(value) => setCountry(value)}
          value={country}
          disabled={countryList.length === 1}
        />
      </div>
      <SearchFields
        onSearch={(inputBicCode, inputCity, inputBankName) => {
          setBicCode(inputBicCode);
          setCity(inputCity);
          setBankName(inputBankName);
        }}
        searchLabel={fieldLabel}
      />
      {branchDataQuery.isLoading ? (
        <div className='flex-grow grid place-content-center'>
          <Translate value='loading' />
        </div>
      ) : !branchDataQuery.isFetched && !branchDataQuery.data ? (
        <div className='flex-grow grid place-content-center'>
          <Translate value='bic_search_fallback_text' />
        </div>
      ) : branchDataQuery.isError ? (
        <div className='flex-grow grid place-content-center'>
          <Translate value='fetch_error' />
        </div>
      ) : (
        <DataTable
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          totalRecords={tableData?.length || 0}
          className='mt-4 mb-6 flex-grow overflow-auto'
          selectionMode='single'
          onSelectionChange={(e: { value: any }) => onBicCodeSelect(e.value)}
          size='small'
          dataKey={isSwiftBicCode ? 'Bic' : 'NationalId'}
          value={tableData}
          loading={branchDataQuery.isFetching}
        >
          <Column field={isSwiftBicCode ? 'Bic' : 'NationalId'} header={fieldLabel} sortable />
          <Column field='InstitutionName' header={translateWithValues('bank_name')} sortable />
          <Column field='table_column_city' header={translateWithValues('city')} sortable />
          <Column field='table_column_address' header={translateWithValues('address')} sortable />
          <Column
            field='BranchInformation'
            header={translateWithValues('bank_information')}
            sortable
          />
          <Column field='OpZipCode' header={translateWithValues('bank_code')} sortable />
        </DataTable>
      )}
      <InfoTemplate
        titleKey='beneficiary_info_card.title'
        descriptionKey='beneficiary_info_card.description'
        buttonKey='beneficiary_info_card.button'
        btnAction={closeModal}
      />
    </div>
  );
};

function SearchFields({
  onSearch,
  searchLabel,
}: {
  onSearch: (inputBicCode: string, inputCity: string, inputBankName: string) => void;
  searchLabel: string;
}) {
  const [inputBicCode, setInputBicCode] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputBankName, setInputBankName] = useState('');

  return (
    <div className='flex flex-col lg:flex-row gap-5 py-4 border-b-2 border-neutral-border-2'>
      <div className='flex gap-5'>
        <TextInput
          value={inputBicCode}
          onChange={(e) => setInputBicCode(e.target.value)} 
          label={translateWithValues('swift_bic.search_label', { searchLabel })}
          placeholder='swift_bic.search_placeholder'
          iconPosition='left'
          searchIcon={<Search />}
          iconClassName='mt-[-8px]'
        />
        <TextInput
          value={inputCity}
          onChange={(e) => setInputCity(e.target.value)}
          label='search_by_city'
          placeholder='search_by_city'
        />
        <TextInput
          value={inputBankName}
          onChange={(e) => setInputBankName(e.target.value)}
          label='search_by_bank_name'
          placeholder='search_by_bank_name'
        />
      </div>
      <div className='flex gap-5 items-end'>
        <Button
          severity='info'
          disabled={!inputBicCode && !inputCity && !inputBankName}
          onClick={() => onSearch(inputBicCode, inputCity, inputBankName)}
        >
          <Translate value='search' />
        </Button>
        <Button
          severity='secondary'
          onClick={() => {
            setInputBicCode('');
            setInputCity('');
            setInputBankName('');
          }}
        >
          <Translate value='clear' />
        </Button>
      </div>
    </div>
  );
}

export default BranchLookupModal;
