import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ApprovalHistory, CustomTabView } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import { selectLoading } from '../../../../store/slices/main/mainSlice';
import { AddOrEditOrView } from '../../../../types';
import RolesAssigned from '../AddUser/RolesAssigned';
import UserDetails from '../AddUser/UserDetails';

const ViewUser = ({ mode }: { mode: AddOrEditOrView }) => {
  const { userId: paramUserId } = useParams<{ userId?: string }>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    setPageHeadData({
      title: 'user.view.title',
      description: 'user.view.description',
      className: 'pb-[16px]',
      button: (
        <>
          {mode && [AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
            <Button
              severity='secondary'
              className='h-[45px]'
              onClick={() => {
                setShowHistory(true);
              }}
            >
              <Translate value='approval.history' />
            </Button>
          )}
        </>
      ),
    });
  }, [setPageHeadData]);

  const tabs = [
    {
      header: 'user_details.title',
      content: (
        <UserDetails
          mode={mode}
          onSuccess={() => {
            // Do nothing
          }}
          userId={paramUserId}
        />
      ),
      loading: isLoading,
    },
    {
      header: 'add_user.roles_assigned',
      content: (
        <RolesAssigned mode={AddOrEditOrView.View} userId={paramUserId} roleType='primary' />
      ),
      loading: isLoading,
      isDisabled: !paramUserId,
    },
    {
      header: 'aoboRoles',
      content: <RolesAssigned mode={AddOrEditOrView.View} userId={paramUserId} roleType='aobo' />,
      loading: isLoading,
      isDisabled: !paramUserId,
    },
  ];

  return (
    <>
      <ApprovalHistory
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        recodrdId={paramUserId ?? ''}
        feature='MANAGE_USER'
      />
      {mode === AddOrEditOrView.Approve && (
        <>
          <UserDetails
            mode={mode}
            onSuccess={() => {
              // Do nothing
            }}
            userId={paramUserId}
          />
          <div className='mt-5'>
            <RolesAssigned mode={AddOrEditOrView.Approve} userId={paramUserId} roleType='primary' />
          </div>
          <div className='mt-5'>
            <RolesAssigned mode={AddOrEditOrView.Approve} userId={paramUserId} roleType='aobo' />
          </div>
        </>
      )}
      {mode == AddOrEditOrView.View && <CustomTabView tabs={tabs} initialIndex={0} />}
    </>
  );
};

export default ViewUser;
