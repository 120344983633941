import { Outlet } from 'react-router';
import { PageHeadProvider } from '../../../providers/PageHead';

const RateRequestOutlet = () => {
  return (
    <>
      <PageHeadProvider>
        <div className='px-[1.25rem] lg:px-[3.063rem]'>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default RateRequestOutlet;
